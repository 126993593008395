import React, { useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Stack } from "@mui/material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import UploadButtonComponent from "../buttons/UploadButtonComponent";
import _ from "lodash";
import { Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import DeleteButtonComponent from "../buttons/DeleteButtonComponent";
import { apiCall } from "../../services/api";
import Box from "@mui/material/Box";
import DeleteIcon from "@mui/icons-material/Delete";
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash";
import ClearIcon from "@mui/icons-material/Clear";

const getInitialValues = (values) => {
  if (_.isEmpty(values) || _.isEmpty(values[0]) || Object.keys(values[0]).length === 0) {
    console.log("newRows get intitial values values a");
    // return [{ serialNo: 1 }];
    return [];
  }
  console.log("newRows get intitial values values b", values);
  return values;
};

const AttachmentComponent = ({
  values = [],
  handleRecordSelection = (e) => {},
  fieldName,
  disabled = false,
  entityType,
  width = "25vw",
  mode = "edit",
  formErrors = {},
  setFormErrors = () => {},
  setError = () => {},
  parentGlobalConstants = {},
}) => {
  const [files, setFiles] = useState(getInitialValues(values));
  const [loading, setLoading] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState(null);

  const handleDeleteFile = async (fileIndex, fileId) => {
    try {
      setLoading(true);
      await apiCall("delete", `/api/file/${fileId}`);
      setSnackBarMessage(`File Deleted successfully`);
      const newFiles = files.filter((row, i) => {
        return i !== fileIndex;
      });
      setNewFiles(newFiles);
    } catch (error) {
      console.error("Error uploading file:", error);
      setSnackBarMessage("File Upload failed with error", error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleFileUpload = (file) => {
    file.new = true;
    setNewFiles([...files, file]);
  };

  const isDuplicateFileName = (fileName) => {
    return !_.isEmpty(files) && files.some((file) => file.name === fileName);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackBarMessage(null);
  };

  function setNewFiles(newFiles) {
    setFiles(newFiles);
    handleRecordSelection({
      target: { name: fieldName, value: [...newFiles], type: "formTable" },
    });
  }

  const handleDeleteFileToggle = (fileIndex, deleted) => {
    const newFiles = files.map((file, i) => {
      return i !== fileIndex ? file : { ...file, deleted: deleted };
    });
    if (deleted) {
      setSnackBarMessage("The row will permanently delete only after pressing update");
    }
    setNewFiles(newFiles);
  };

  const handleFetchPdf = async (fileId) => {
    try {
      const response = await apiCall("get", `/api/file/${fileId}`, null);
      window.open(response.url, "_blank");
    } catch (error) {
      console.error("Error fetching PDF:", error);
    }
  };

  const action = (
    <React.Fragment>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <>
      <Box p={2} borderRadius={0} border="2px dashed #ccc" width={width}>
        <Typography variant="h6" align="center" gutterBottom>
          Attachments
        </Typography>
        <Stack direction="column" alignItems="center" spacing={5}>
          <Stack direction="column" alignItems="center" spacing={4}>
            {files &&
              !_.isEmpty(files) &&
              files.map((file, fileIndex) => {
                return (
                  <Stack key={file.id} direction="row" alignItems="center" spacing={2}>
                    <IconButton aria-label="open file" size="small" onClick={() => handleFetchPdf(file.id)}>
                      <PictureAsPdfIcon fontSize="large" sx={{ color: "red", textDecoration: file.deleted ? "line-through" : "none" }} />
                    </IconButton>
                    <Typography
                      sx={{
                        textDecoration: file.deleted ? "line-through" : "none",
                        color: file.deleted ? "red" : "inherit",
                      }}
                    >
                      {file.name}
                    </Typography>
                    {!file.new &&
                      !disabled &&
                      (file.deleted ? (
                        <IconButton
                          disabled={!file.deleted}
                          aria-label="restore"
                          size="small"
                          name={`file-${fileIndex}`}
                          onClick={(event) => {
                            handleDeleteFileToggle(fileIndex, false);
                          }}
                        >
                          <RestoreFromTrashIcon fontSize="medium" color="success" name={`file-${fileIndex}`} />
                        </IconButton>
                      ) : (
                        <IconButton
                          disabled={file.deleted}
                          aria-label="delete"
                          size="small"
                          name={`file-${fileIndex}`}
                          onClick={(event) => {
                            handleDeleteFileToggle(fileIndex, true);
                          }}
                        >
                          <DeleteIcon fontSize="medium" name={`file-${fileIndex}`} />
                        </IconButton>
                      ))}
                    {!loading && !disabled && file.new && (
                      <DeleteButtonComponent handleDelete={() => handleDeleteFile(fileIndex, file.id)} itemType={"File"} icon={<ClearIcon />} />
                    )}
                  </Stack>
                );
              })}
          </Stack>
          {loading ? (
            <CircularProgress />
          ) : !disabled ? (
            <UploadButtonComponent handleFileUpload={handleFileUpload} isDuplicateFileName={isDuplicateFileName} entityType={entityType} />
          ) : (
            <></>
          )}
          <Snackbar
            open={snackBarMessage}
            autoHideDuration={3000}
            onClose={handleClose}
            message={snackBarMessage}
            action={action}
            anchorOrigin={{
              vertical: "center",
              horizontal: "right",
            }}
          />
        </Stack>
      </Box>
    </>
  );
};

export default AttachmentComponent;
