import { apiCall, setTokenHeader } from "../../services/api";
import { SET_CURRENT_USER } from "../actionTypes";
import { addError, removeError } from "./errors";

export function setCurrentUser(user) {
  return {
    type: SET_CURRENT_USER,
    user,
  };
}

export function setAuthorizationToken(token) {
  setTokenHeader(token);
}

export function logout() {
  console.log("comes here to logout");
  return (dispatch) => {
    localStorage.clear();
    setAuthorizationToken(false);
    dispatch(setCurrentUser({}));
  };
}

export function authUser(type, userData) {
  return (dispatch) => {
    // wrap our thunk in a promise so we can wait for the API call
    return new Promise(async (resolve, reject) => {
      try {
        let response = await apiCall("post", `/api/auth/${type}`, userData);
        let { token, validExistingSession, verifyTFA, ...otherFields } = response;
        console.log("otherFields are", otherFields);
        localStorage.setItem("jwtToken", token);
        setAuthorizationToken(token);
        dispatch(removeError());
        if (validExistingSession || verifyTFA) {
          resolve({ validExistingSession, verifyTFA, ...otherFields });
        } else {
          dispatch(setCurrentUser(otherFields));
          resolve({ validExistingSession: false, verifyTFA: false, ...otherFields });
        }
      } catch (err) {
        console.log("error inside auth.js", err);
        dispatch(addError(err.message || err));
        reject(err); // indicate the API call failed
      }
    });
  };
}
