import { formatDate } from "../services/dateUtils";
import { translatePicklistValue } from "../services/fieldsUtils";
import { materialTypeValues, inTransactionTypeValues, receiverDocTypeValues, senderDocTypeValues } from "../standardEntities/materialInwardFields";
import { projectTypeValues } from "../standardEntities/project";

export const entityType = "stock";

export const stockViewColumns = [
  {
    name: "docLocatorCode",
    label: "Locator",
    type: "text",
  },
  {
    name: "locatorName",
    label: "Locator Name",
    type: "text",
  },
  {
    name: "projectCode",
    label: "Project",
    type: "text",
  },
  {
    name: "projectType",
    label: "Project Type",
    type: "text",
    format: (value) => translatePicklistValue(value, projectTypeValues),
  },
  {
    name: "projectName",
    label: "Project Name",
    type: "text",
  },
  {
    name: "itemName",
    label: "Item Name",
    type: "text",
  },
  {
    name: "itemCustCode",
    label: "Part Code",
    type: "text",
  },
  {
    name: "uom",
    label: "UOM",
    type: "text",
  },
  {
    name: "totalAvailableQuantity",
    label: "Available Qty",
    type: "text",
  },
  {
    name: "totalAvailableAmount",
    label: "Available Amount",
    type: "text",
  },
  {
    name: "totalPhysicalQuantity",
    label: "Physical Qty",
    type: "text",
  },
  {
    name: "totalPhysicalAmount",
    label: "Physical Amount",
    type: "text",
  },
];

export const stockSearchColumns = [
  {
    name: "materialInwardDtl.docLocatorCode",
    label: "Locator",
    type: "text",
  },
  {
    name: "locatorName",
    label: "Locator Name",
    type: "text",
  },
  {
    name: "materialInwardDtl.projectCode",
    label: "Project Code",
    type: "text",
  },
  {
    name: "$project.projectType$",
    label: "Project Type",
    type: "picklist",
    values: [
      { value: "FF", label: "FF  Work" },
      { value: "FTTH", label: "FTTH Work" },
    ],
  },
  {
    name: "projectName",
    label: "Project Name",
    type: "text",
  },
  {
    name: "itemName",
    label: "Item Name",
    type: "text",
  },
  {
    name: "materialInwardDtl.itemCustCode",
    label: "Part Code",
    type: "text",
  },
  {
    name: "transactionType",
    label: "Transaction Type",
    type: "picklist",
    defaultValue: "",
    values: [
      { value: "PU", label: "Purchase" },
      { value: "OT", label: "Owner Site Transfer" },
      { value: "ET", label: "External Site Transfer" },
      { value: "MS", label: "Main Store Transfer" },
      { value: "CS", label: "Contractor Transfer" },
      { value: "OS", label: "Opening Stock" },
      { value: "MG", label: "Misc. Gain" },
      { value: "IS", label: "Internal Shift" },
    ],
  },
  {
    name: "materialType",
    label: "Material Type",
    type: "picklist",
    defaultValue: "",
    values: [
      { value: "F", label: "Fresh" },
      { value: "R", label: "Repaired" },
      { value: "D", label: "Defective" },
    ],
  },
  {
    name: "approved",
    label: "Submit",
    type: "picklist",
    defaultValue: "",
    values: [
      { value: true, label: "True" },
      { value: false, label: "False" },
    ],
  },
];

export const stockViewExcelColumns = [
  {
    name: "docLocatorCode",
    label: "Locator Code",
    type: "text",
  },
  {
    name: "locatorName",
    label: "Locator Name",
    type: "text",
  },
  {
    name: "docStationName",
    label: "Station Name",
    type: "text",
  },
  {
    name: "docCompanyCode",
    label: "In Company",
    type: "text",
  },
  {
    name: "docCompanyName",
    label: "In Company Name",
    type: "text",
  },
  {
    name: "projectCode",
    label: "Project Code",
    type: "text",
  },
  {
    name: "projectType",
    label: "Project Type",
    type: "text",
    format: (value) => translatePicklistValue(value, projectTypeValues),
  },
  {
    name: "projectName",
    label: "Project Name",
    type: "text",
  },
  {
    name: "itemName",
    label: "Item Name",
    type: "text",
  },
  {
    name: "itemId",
    label: "Item Id",
    type: "text",
  },
  {
    name: "itemCustCode",
    label: "Part Code",
    type: "text",
  },
  {
    name: "uom",
    label: "UOM",
    type: "text",
  },
  {
    name: "totalAvailableQuantity",
    label: "Available Qty",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "totalAvailableAmount",
    label: "Available Amount",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "totalPhysicalQuantity",
    label: "Physical Qty",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "totalPhysicalAmount",
    label: "Physical Amount",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
];

export const stockViewDetailExcelColumns = [
  {
    name: "mirId",
    label: "MIR No",
    type: "text",
  },
  {
    name: "docDate",
    label: "MIR Date",
    type: "date",
    format: (value) => formatDate(value),
  },
  {
    name: "docLocatorCode",
    label: "In Locator",
    type: "text",
  },
  {
    name: "docLocatorName",
    label: "Inward Locator Name",
    type: "text",
  },
  {
    name: "docStationName",
    label: "Station Name",
    type: "text",
  },
  {
    name: "projectCode",
    label: "Project",
    type: "text",
  },
  {
    name: "projectType",
    label: "Project Type",
    type: "text",
    format: (value) => translatePicklistValue(value, projectTypeValues),
  },
  {
    name: "projectName",
    label: "Project Name",
    type: "text",
  },
  {
    name: "docCompanyCode",
    label: "In Company",
    type: "text",
  },
  {
    name: "docCompanyName",
    label: "Inward Company Name",
    type: "text",
  },
  {
    name: "receiverDocType",
    label: "Receiver Doc Type",
    type: "text",
    format: (value) => receiverDocTypeValues.find((item) => item.value === value).label,
  },
  {
    name: "receiverDocNo",
    label: "Receiver Doc No",
    type: "text",
  },
  {
    name: "receiverDocDate",
    label: "Receiver Doc Date",
    type: "date",
    format: (value) => formatDate(value),
  },
  {
    name: "transactionType",
    label: "Trans Type",
    type: "text",
    format: (value) => inTransactionTypeValues.find((item) => item.value === value).label,
  },
  {
    name: "materialType",
    label: "Mat Type",
    type: "text",
    format: (value) => materialTypeValues.find((item) => item.value === value).label,
  },
  {
    name: "senderLocatorCode",
    label: "Sender Locator",
    type: "text",
  },
  {
    name: "senderLocatorName",
    label: "Sender Locator Name",
    type: "text",
  },
  {
    name: "senderStationName",
    label: "Sender Station",
    type: "text",
  },
  {
    name: "senderStateName",
    label: "State/Country",
    type: "text",
  },
  {
    name: "senderCompanyCode",
    label: "Sender Company",
    type: "text",
  },
  {
    name: "senderCompanyName",
    label: "Sender Company Name",
    type: "text",
  },
  {
    name: "senderDocType",
    label: "Sender Doc Type",
    type: "text",
    format: (value) => senderDocTypeValues.find((item) => item.value === value).label,
  },
  {
    name: "senderDocNo",
    label: "Sender Doc No",
    type: "text",
  },
  {
    name: "senderDocDate",
    label: "Sender Doc Date",
    type: "date",
    format: (value) => formatDate(value),
  },
  {
    name: "id",
    label: "MIR Row Id",
    type: "text",
  },
  {
    name: "itemName",
    label: "Item Name",
    type: "text",
  },
  {
    name: "itemId",
    label: "Item Id",
    type: "text",
  },
  {
    name: "itemCustCode",
    label: "Part Code",
    type: "text",
  },
  {
    name: "uom",
    label: "Unit",
    type: "text",
  },
  {
    name: "totalQuantity",
    label: "Inward Quantity",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "rate",
    label: "Rate",
    type: "number",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "availableQuantity",
    label: "Available Qty",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "availableAmount",
    label: "Available Amount",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "physicalQuantity",
    label: "Physical Qty",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "physicalAmount",
    label: "Physical Amount",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "submit",
    label: "Submit",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
];

export const stockViewInwardExcelColumns = [
  {
    name: "docLocatorCode",
    label: "In Locator",
    type: "text",
  },
  {
    name: "locatorName",
    label: "Locator Name",
    type: "text",
  },
  {
    name: "docStationName",
    label: "Station Name",
    type: "text",
  },
  {
    name: "docCompanyCode",
    label: "In Company",
    type: "text",
  },
  {
    name: "docCompanyName",
    label: "In Company Name",
    type: "text",
  },

  {
    name: "projectCode",
    label: "Project",
    type: "text",
  },
  {
    name: "projectName",
    label: "Project Name",
    type: "text",
  },
  {
    name: "mirId",
    label: "MIR No",
    type: "text",
  },
  {
    name: "docDate",
    label: "MIR Date",
    type: "date",
    format: (value) => formatDate(value),
  },
  {
    name: "transactionType",
    label: "Trans Type",
    type: "text",
    format: (value) => inTransactionTypeValues.find((item) => item.value === value).label,
  },
  {
    name: "materialType",
    label: "Mat Type",
    type: "text",
    format: (value) => materialTypeValues.find((item) => item.value === value).label,
  },
  {
    name: "senderCompanyCode",
    label: "Sender Company",
    type: "text",
  },
  {
    name: "senderCompanyName",
    label: "Sender Company Name",
    type: "text",
  },
  {
    name: "senderLocatorCode",
    label: "Sender Locator",
    type: "text",
  },
  {
    name: "senderLocatorName",
    label: "Sender Locator Name",
    type: "text",
  },
  {
    name: "senderStationName",
    label: "Sender Station",
    type: "text",
  },
  {
    name: "senderStateName",
    label: "State/Country",
    type: "text",
  },
  {
    name: "basicValue",
    label: "Basic Value",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "inventoryValue",
    label: "Inventory Value",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "submit",
    label: "Submit",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
  {
    name: "itemName",
    label: "Item Name",
    type: "text",
  },
  {
    name: "itemId",
    label: "Item Id",
    type: "text",
  },
  {
    name: "itemCustCode",
    label: "Part Code",
    type: "text",
  },
  {
    name: "uom",
    label: "Unit",
    type: "text",
  },
  {
    name: "inwardQuantity",
    label: "Inward Quantity",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "inwardAmount",
    label: "Inward Amount",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "totalAvailableQuantity",
    label: "Available Qty",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "totalAvailableAmount",
    label: "Available Amount",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "totalPhysicalQuantity",
    label: "Physical Qty",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "totalPhysicalAmount",
    label: "Physical Amount",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
];

export const stockViewRowExcelColumns = [
  {
    name: "docLocatorCode",
    label: "In Locator",
    type: "text",
  },
  {
    name: "locatorName",
    label: "Locator Name",
    type: "text",
  },
  {
    name: "docStationName",
    label: "Station Name",
    type: "text",
  },
  {
    name: "docCompanyCode",
    label: "In Company",
    type: "text",
  },
  {
    name: "docCompanyName",
    label: "In Company Name",
    type: "text",
  },
  {
    name: "projectCode",
    label: "Project",
    type: "text",
  },
  {
    name: "projectName",
    label: "Project Name",
    type: "text",
  },
  {
    name: "mirId",
    label: "MIR No",
    type: "text",
  },
  {
    name: "docDate",
    label: "MIR Date",
    type: "date",
    format: (value) => formatDate(value),
  },
  {
    name: "transactionType",
    label: "Trans Type",
    type: "text",
    format: (value) => inTransactionTypeValues.find((item) => item.value === value).label,
  },
  {
    name: "materialType",
    label: "Mat Type",
    type: "text",
    format: (value) => materialTypeValues.find((item) => item.value === value).label,
  },
  {
    name: "senderCompanyCode",
    label: "Sender Company",
    type: "text",
  },
  {
    name: "senderCompanyName",
    label: "Sender Company Name",
    type: "text",
  },
  {
    name: "senderLocatorCode",
    label: "Sender Locator",
    type: "text",
  },
  {
    name: "senderLocatorName",
    label: "Sender Locator Name",
    type: "text",
  },
  {
    name: "senderStationName",
    label: "Sender Station",
    type: "text",
  },
  {
    name: "senderStateName",
    label: "State/Country",
    type: "text",
  },
  {
    name: "basicValue",
    label: "Basic Value",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "inventoryValue",
    label: "Inventory Value",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "submit",
    label: "Submit",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
  {
    name: "mirRowId",
    label: "MIR Row Id",
    type: "text",
  },
  {
    name: "itemName",
    label: "Item Name",
    type: "text",
  },
  {
    name: "itemId",
    label: "Item Id",
    type: "text",
  },
  {
    name: "itemCustCode",
    label: "Part Code",
    type: "text",
  },
  {
    name: "uom",
    label: "Unit",
    type: "text",
  },
  // {
  //   name: "partSerialNo",
  //   label: "Part Serial",
  //   type: "text",
  // },
  // {
  //   name: "batchNo",
  //   label: "Batch No",
  //   type: "text",
  // },
  {
    name: "inwardQuantity",
    label: "Inward Quantity",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "inwardAmount",
    label: "Inward Amount",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "rate",
    label: "Rate",
    type: "number",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "totalAvailableQuantity",
    label: "Available Qty",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "totalAvailableAmount",
    label: "Available Amount",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "totalPhysicalQuantity",
    label: "Physical Qty",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "totalPhysicalAmount",
    label: "Physical Amount",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
];

export const stockOpenCloseAvailableColumns = [
  {
    name: "docLocatorCode",
    label: "Locator Code",
    type: "text",
  },
  {
    name: "locatorName",
    label: "Locator Name",
    type: "text",
  },
  {
    name: "projectCode",
    label: "Project Code",
    type: "text",
  },
  {
    name: "projectName",
    label: "Project Name",
    type: "text",
  },
  {
    name: "itemName",
    label: "Item Name",
    type: "text",
  },
  {
    name: "itemId",
    label: "Item Id",
    type: "text",
  },
  {
    name: "itemCustCode",
    label: "Part Code",
    type: "text",
  },
  {
    name: "uom",
    label: "UOM",
    type: "text",
  },
  {
    name: "totalAvailableQuantityOpening",
    label: "Available Qty Open",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "inwardQuantity",
    label: "Qty Inward",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "outwardQuantity",
    label: "Qty Outward",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "consumedQuantity",
    label: "Qty Consumed",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "totalAvailableQuantityClosing",
    label: "Available Qty Close",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
];

export const stockOpenClosePhysicalColumns = [
  {
    name: "docLocatorCode",
    label: "Locator Code",
    type: "text",
  },
  {
    name: "locatorName",
    label: "Locator Name",
    type: "text",
  },
  {
    name: "projectCode",
    label: "Project Code",
    type: "text",
  },
  {
    name: "projectName",
    label: "Project Name",
    type: "text",
  },
  {
    name: "itemName",
    label: "Item Name",
    type: "text",
  },
  {
    name: "itemId",
    label: "Item Id",
    type: "text",
  },
  {
    name: "itemCustCode",
    label: "Part Code",
    type: "text",
  },
  {
    name: "uom",
    label: "UOM",
    type: "text",
  },
  {
    name: "totalPhysicalQuantityOpening",
    label: "Physical Qty Open",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "inwardQuantity",
    label: "Qty Inward",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "outwardQuantity",
    label: "Qty Outward",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "consumedQuantity",
    label: "Qty Consumed",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "totalPhysicalQuantityClosing",
    label: "Physical Qty Close",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
];

export const stockAgeingAvailableColumns = [
  {
    name: "docLocatorCode",
    label: "Locator Code",
    type: "text",
  },
  {
    name: "locatorName",
    label: "Locator Name",
    type: "text",
  },
  {
    name: "docStationName",
    label: "Station Name",
    type: "text",
  },
  {
    name: "docCompanyCode",
    label: "In Company",
    type: "text",
  },
  {
    name: "docCompanyName",
    label: "In Company Name",
    type: "text",
  },
  {
    name: "projectCode",
    label: "Project Code",
    type: "text",
  },
  {
    name: "projectName",
    label: "Project Name",
    type: "text",
  },
  {
    name: "itemName",
    label: "Item Name",
    type: "text",
  },
  {
    name: "itemId",
    label: "Item Id",
    type: "text",
  },
  {
    name: "itemCustCode",
    label: "Part Code",
    type: "text",
  },
  {
    name: "uom",
    label: "UOM",
    type: "text",
  },
  {
    name: "within30_availableQuantity",
    label: "<= 30 Days Qty",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "30To60_availableQuantity",
    label: "31 to 60 Days Qty",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "60To120_availableQuantity",
    label: "61 to 120 Days Qty",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "120To360_availableQuantity",
    label: "121 to 360 Days Qty",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "moreThan360_availableQuantity",
    label: "> 360 Days Qty",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "totalAvailableQuantity",
    label: "Available Qty",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "within30_availableAmount",
    label: "<= 30 Days Amount",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "30To60_availableAmount",
    label: "31 to 60 Days Amount",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "60To120_availableAmount",
    label: "61 to 120 Days Amount",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "120To360_availableAmount",
    label: "121 to 360 Days Amount",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "moreThan360_availableAmount",
    label: "> 360 Days Amount",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "totalAvailableAmount",
    label: "Available Amount",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
];

export const stockAgeingPhysicalColumns = [
  {
    name: "docLocatorCode",
    label: "Locator Code",
    type: "text",
  },
  {
    name: "locatorName",
    label: "Locator Name",
    type: "text",
  },
  {
    name: "docStationName",
    label: "Station Name",
    type: "text",
  },
  {
    name: "docCompanyCode",
    label: "In Company",
    type: "text",
  },
  {
    name: "docCompanyName",
    label: "In Company Name",
    type: "text",
  },
  {
    name: "projectCode",
    label: "Project Code",
    type: "text",
  },
  {
    name: "projectName",
    label: "Project Name",
    type: "text",
  },
  {
    name: "itemName",
    label: "Item Name",
    type: "text",
  },
  {
    name: "itemId",
    label: "Item Id",
    type: "text",
  },
  {
    name: "itemCustCode",
    label: "Part Code",
    type: "text",
  },
  {
    name: "uom",
    label: "UOM",
    type: "text",
  },
  {
    name: "within30_physicalQuantity",
    label: "<= 30 Days Qty",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "30To60_physicalQuantity",
    label: "31 to 60 Days Qty",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "60To120_physicalQuantity",
    label: "61 to 120 Days Qty",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "120To360_physicalQuantity",
    label: "121 to 360 Days Qty",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "moreThan360_physicalQuantity",
    label: "> 360 Days Qty",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "totalPhysicalQuantity",
    label: "Physical Qty",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "within30_physicalAmount",
    label: "<= 30 Days Amount",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "30To60_physicalAmount",
    label: "31 to 60 Days Amount",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "60To120_physicalAmount",
    label: "61 to 120 Days Amount",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "120To360_physicalAmount",
    label: "121 to 360 Days Amount",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "moreThan360_physicalAmount",
    label: "> 360 Days Amount",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "totalPhysicalAmount",
    label: "Physical Amount",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
];
