import React, { useState, useEffect } from "react";
import { getFieldsForRecordType } from "../../services/standardEntityFieldService";
import { transactionEntities, getPageTitle } from "../../services/standardEntityFieldService";
import { generatePDFContent } from "../../services/pdfContentService";
import { apiCall } from "../../services/api";
import { useParams } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Paper from "@mui/material/Paper";
import CircularProgress from "@mui/material/CircularProgress";
import withAuth from "../../hocs/withAuth";
import { Link } from "react-router-dom";
import _ from "lodash";
import { Switch } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import LookupField from "../inputFields/LookupField";
import TableInput from "../inputFields/TableInput";
import dayjs from "dayjs";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import PDFGenerator from "../buttons/PdfGenerator";
import AttachmentComponent from "../inputFields/AttachmentComponent";
import { evaluateFilter } from "../../services/filterEvaluator";

let globalConstants = {};
function RecordFullViewForm({
  fields = [],
  formMinWidth = "37vw",
  entityType,
  isValidRecord = () => true,
  creationAllowed = true,
  selectedRecord,
  setSelectedRecord,
  isEditable = () => true,
  hasBackButton = () => true,
  currentUser,
  pageTitle = "",
}) {
  let { id, recordType } = useParams();
  console.log("Pawan id is", id);
  let recordId = id ? id : selectedRecord.id;
  recordType = entityType ? entityType : recordType;
  if (_.isEmpty(fields)) {
    fields = getFieldsForRecordType(recordType);
  }

  console.log("recordType is", recordType);

  if (_.isEmpty(pageTitle)) {
    pageTitle = getPageTitle(recordType);
  }

  console.log("id is", id);
  const [error, setError] = useState(null);
  const [record, setRecord] = useState(selectedRecord);
  const [successMessage, setSuccessMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (_.isEmpty(id)) {
      return;
    }
    setLoading(true);
    console.log("going to make request for id", id);
    apiCall("get", `/api/entity/${recordType}/${id}`)
      .then((response) => {
        console.log("record fetched is", response);

        if (response == null) {
          console.log("Null response for records fetch");
          return;
        }
        console.log("values for table input new record is", response);
        setRecord(response);
      })
      .catch((err) => {
        console.log("Error fetching records", err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id, recordType]);

  useEffect(() => {
    setError(null);
    setSuccessMessage(null);
  }, [record]);

  console.log("fields are", fields);
  //todo: govern this by global constants kind of structure
  if (recordType === "material-inward") {
    if (record && record["inventoryValue"] && record["basicValue"]) {
      let dividend = parseFloat(record["inventoryValue"]);
      let divisor = parseFloat(record["basicValue"]);
      if (!isNaN(dividend) && !isNaN(divisor) && divisor !== 0) {
        globalConstants["invByBasic"] = dividend / divisor;
      }
    }
  }
  return (
    <div>
      <Box sx={{ marginTop: 1.5, marginLeft: 0 }}>
        <form>
          <Stack direction="column" alignItems="center" spacing={2}>
            <Stack direction="row" alignItems="center" spacing={2} sx={{ ml: 0 }}>
              <h3 className="sub-header"> {pageTitle}</h3>
              {creationAllowed && (
                <Button
                  sx={{
                    width: "80px",
                    height: "45px",
                    fontSize: "0.8rem",
                  }}
                  variant="contained"
                  startIcon={<AddCircleIcon />}
                  component={Link}
                  to={`/${recordType}/add/`}
                >
                  New
                </Button>
              )}
              {transactionEntities.includes(recordType) &&
                (loading || !record || record.id == null ? (
                  <Box sx={{ ml: 22, display: "flex" }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <PDFGenerator
                    generateContent={() => generatePDFContent(fields, record, recordType)}
                    reportTitle={`${recordType}/${id}`}
                    record={record}
                  />
                ))}
            </Stack>
            <Paper
              sx={{
                minWidth: formMinWidth,
                minHeight: "82vh",
                marginLeft: 0,
                backgroundColor: "#F7F6FF",
              }}
              className="flexPaper"
              elevation={10}
            >
              <Stack sx={{ mt: 4, mb: 4 }} direction="column" alignItems="center" spacing={2}>
                {loading || !record ? (
                  <Box sx={{ ml: 0, display: "flex" }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  fields.map((field) => getJSXForField(field))
                )}
              </Stack>
            </Paper>
            {error && (
              <div style={{ width: formMinWidth, justifyContent: "center" }} className="alert alert-danger">
                {error}
              </div>
            )}
            <Stack direction="row" alignItems="center" spacing={2}>
              {!hasBackButton() ? (
                <></>
              ) : selectedRecord ? (
                <Button
                  variant="contained"
                  color="error"
                  // component={Link}
                  onClick={() => {
                    console.log("comes here to set selected record null");
                    setSelectedRecord(null);
                  }}
                  // to={`/${recordType}/view`}
                >
                  Back
                </Button>
              ) : (
                <Button variant="contained" color="error" component={Link} to={`/${recordType}/view`}>
                  Back
                </Button>
              )}
              {isEditable() && (
                <Button
                  variant="contained"
                  component={Link}
                  disabled={record?.cancelled || record?.delivered || record?.closed}
                  to={`/${recordType}/update/${recordId}`}
                >
                  Edit
                </Button>
              )}
            </Stack>
          </Stack>
          <h2> </h2>
        </form>

        {successMessage !== null && (
          <Alert severity="success">
            <AlertTitle>Success</AlertTitle>
            {successMessage}
          </Alert>
        )}
      </Box>
    </div>
  );

  function getJSXForField(field, width = "21vw") {
    width = field.props && field.props.width ? field.props.width : width;
    if (!_.isEmpty(field.visibility)) {
      let isVisible = evaluateFilter(field.visibility, record);
      if (!isVisible) {
        return <></>;
      }
    }

    switch (field.type) {
      case "text":
      case "number":
        return (
          <TextField
            id={field.name}
            name={field.name}
            label={field.label}
            disabled={true}
            variant={field.autoFilled || field.displayOnly ? "outlined" : "outlined"}
            type="text"
            sx={{ mt: 2, width: width }}
            value={getFieldValue(record, field)}
            InputLabelProps={{
              shrink: true, // Enable label animation
            }}
            //   inputProps={{
            //     min: "0", // Set the minimum value to 0
            //     step: "any", // Allow decimal input
            // }}
          />
        );

      case "date":
        return (
          <DatePicker
            name={field.name}
            label={field.label}
            value={record && record[field.name] ? dayjs(record[field.name]) : null}
            disabled={true}
            sx={{ mt: 2, width: width }}
            format="DD/MM/YYYY"
            onChange={(date) => {
              console.log("date type is", typeof date);
              console.log("date", date);
              console.log("date", date instanceof Date);
            }}
          />
        );

      case "textArea":
        return (
          <TextField
            id={field.name}
            name={field.name}
            label={field.label}
            disabled={true}
            type="text"
            sx={{ mt: 2, width: width }}
            multiline
            rows={4}
            required
            value={record ? record[field.name] : ""}
            InputLabelProps={{
              shrink: true, // Enable label animation
            }}
          />
        );
      case "picklist":
        return (
          <FormControl sx={{ width: width }}>
            <InputLabel id={field.name}>{field.label}</InputLabel>
            <Select
              column
              labelId={field.name}
              id={field.name}
              label={field.label}
              name={field.name}
              value={record ? record[field.name] : ""}
              disabled={true}
            >
              <MenuItem value="" disabled>
                {field.label}
              </MenuItem>
              {field.values.map((valueObject) => (
                <MenuItem key={valueObject.value} value={valueObject.value}>
                  {valueObject.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );

      case "checkbox":
        return (
          <FormGroup>
            <FormControlLabel
              required
              control={
                <Switch checked={record ? record[field.name] : false} name={field.name} disabled={true} inputProps={{ "aria-label": "controlled" }} />
              }
              label={field.label}
            />
          </FormGroup>
        );

      case "lookup":
        console.log("field type is table values in full view are", field.name);
        console.log("field type is table values in full view are", record[field.name]);

        return (
          <LookupField width={width} field={field} record={record} disabled={true} value={record[field.name]} handleRecordSelection={(e) => {}} />
        );

      case "formTable":
        return !(record && record[field.name]) ? (
          <TableInput
            key="1"
            columns={field.tableFields}
            title={field.label}
            fieldName={field.name}
            values={[{}]}
            globalConstants={globalConstants}
            autoFilled={true}
            mode="view"
            tableWidth={width}
          />
        ) : (
          <TableInput
            key="2"
            columns={field.tableFields}
            title={field.label}
            fieldName={field.name}
            values={record[field.name]}
            globalConstants={globalConstants}
            autoFilled={true}
            mode="view"
            tableWidth={width}
          />
        );

      case "attachment":
        return !(record && record[field.name]) ? (
          <AttachmentComponent
            key="1"
            fieldName={field.name}
            values={[{}]}
            parentGlobalConstants={globalConstants}
            disabled={true}
            entityType={entityType}
            mode={"view"}
          />
        ) : (
          <AttachmentComponent
            key="2"
            fieldName={field.name}
            values={record[field.name]}
            parentGlobalConstants={globalConstants}
            disabled={true}
            entityType={entityType}
            mode={"view"}
          />
        );
      case "formSection":
        return (
          <Stack
            direction={field.alignment ? field.alignment : "row"}
            alignItems={"top"}
            justifyContent={field.props && field.props.justify ? field.props.justify : "center"}
            spacing={field.props && field.props.spacing ? field.props.spacing : 1.8}
          >
            {field.childFields.map((childField) => getJSXForField(childField, field.props && field.props.width ? field.props.width : "10vw"))}
          </Stack>
        );

      default:
        return <></>;
    }

    function getFieldValue(record, field) {
      console.log("field in get value  is", field);
      if (!field) {
        return "";
      }
      return record && record[field.name] ? record[field.name] : field.type === "number" ? 0 : "";
    }
  }
}

export default withAuth(RecordFullViewForm);
