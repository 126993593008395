import { jsPDF } from "jspdf";
import React, { useState } from "react";
import Button from "@mui/material/Button";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import "jspdf-autotable";
import _ from "lodash";

const options = {
  margin: { top: 40 },
  styles: {
    fontSize: 12,
    font: "Arial",
    lineWidth: 0.75,
  },
  columnStyles: {
    0: { fontStyle: "underline", textColor: [0, 0, 0] },
  },
  bodyStyles: { valign: "top" },
  columnWidth: "auto",
  theme: "plain",
  tableLineColor: [128, 128, 128],
};

const PDFGenerator = ({ generateContent, record }) => {
  const [loading, setLoading] = useState(false);

  const generatePDF = (event) => {
    event.preventDefault();
    setLoading(true);

    let content = generateContent();
    console.log("html content is", content);
    // Create a new jsPDF instance
    let pdf = new jsPDF({
      orientation: "landscape", // Set orientation to portrait
      unit: "pt", // Use pixels as units
      format: "a4", // Set page format to A4
    });

    if (!_.isEmpty(content.rows)) {
      content.rows.forEach((row, index) => {
        pdf.autoTable({
          head: row.headers,
          startY: index === 0 ? 50 : pdf.lastAutoTable.finalY,
          body: row.body,
          ...options,
          didParseCell: function (data) {
            data.cell.styles.valign = "top"; // Vertical alignment
            data.cell.styles.halign = row.halign ? row.halign : "left"; // Horizontal alignment
          },
        });
      });
    }
    // Add the table to the PDF

    pdf.internal.scaleFactor = 1;

    const pageCount = pdf.internal.getNumberOfPages();
    for (let i = 1; i <= pageCount; i++) {
      let pageSize = pdf.internal.pageSize;
      pdf.setPage(i);
      pdf.setFontSize(10);
      pdf.text(
        `Page ${i} of ${pageCount}`,
        pageSize.getWidth() - 100,
        pageSize.getHeight() - 10,
      );
      if (content.footer) {
        const textWidth =
          (pdf.getStringUnitWidth(content.footer) *
            pdf.internal.getFontSize()) /
          pdf.internal.scaleFactor;
        const textX = (pageSize.getWidth() - textWidth) / 2;
        pdf.text(content.footer, textX, pageSize.getHeight() - 10);
      }
    }
    const pdfDataUri = pdf.output("datauristring");
    // Open the PDF in a new tab
    const newTab = window.open();
    newTab.document.write(
      '<iframe width="100%" height="100%" src="' + pdfDataUri + '"></iframe>',
    );
    setLoading(false);
  };

  console.log("data in pdf generator is ", record);
  return (
    <>
      {loading ? (
        <Box sx={{ ml: 22, display: "flex" }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Button
            sx={{ width: "45px", height: "45px" }}
            variant="contained"
            type="submit"
            onClick={generatePDF}
            color="secondary"
          >
            <PictureAsPdfIcon />
          </Button>
        </>
      )}
    </>
  );
};

export default PDFGenerator;
