import React, { useState } from "react";
import Button from "@mui/material/Button";
import TableViewIcon from "@mui/icons-material/TableView";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { Stack } from "@mui/material";
import { generateExcelReport } from "../../services/reportGenerationUtils";

function ExcelReport({
  fechData,
  fileName,
  disabled,
  color = "success",
  title = "",
  setError = (error) => {},
}) {
  const [loading, setLoading] = useState(false);
  
  async function fetchReportData() {
    let fetchResponse = null;
    try {
      setLoading(true);
      fetchResponse = await fechData();
    } catch (err) {
      console.log("API Called Failed With exception", err);
      setError(err.message || err);
    } finally {
      setLoading(false);
    }
    return fetchResponse;
  }

  return (
    <>
      {loading ? (
        <Box sx={{ ml: 22, display: "flex" }}>
          <CircularProgress />
        </Box>
      ) : (
        <Button
          sx={{
            width: "45px",
            height: "45px",
            fontSize: "0.5rem",
          }}
          disabled={disabled}
          variant="contained"
          type="submit"
          onClick={() => generateExcelReport({ fetchReportData, fileName })}
          color={color}
        >
          <Stack>
            <h9>
              <TableViewIcon />
            </h9>
            <h9>{title}</h9>
          </Stack>
        </Button>
      )}
    </>
  );
}

export default ExcelReport;
