import { Field } from "../types/field";
import { translatePicklistValue } from "../services/fieldsUtils";

export const projectTypeValues = [
  { value: "FF", label: "FF  Work" },
  { value: "FTTH", label: "FTTH Work" },
];

export const projectFields: Field[] = [
  {
    name: "projectName",
    label: "Project Name",
    type: "text",
    allowUpdate: true,
  },
  {
    name: "section1",
    type: "formSection",
    childFields: [
      {
        name: "projectType",
        label: "Project Type",
        type: "picklist",
        defaultValue: "FF",
        values: [
          { value: "FF", label: "FF Work" },
          { value: "FTTH", label: "FTTH Work" },
        ],
      },
      {
        name: "routeLength",
        label: "Route Length (Mtr)",
        type: "number",
        allowNull: true,
        allowUpdate: true,
      },
    ],
  },
  {
    name: "section1",
    type: "formSection",
    childFields: [
      {
        name: "stateName",
        label: "State Name",
        type: "lookup",
        lookupType: "state",
        defaultValue: null,
        allowUpdate: false,
        lookupFilter: {
          type: "EQUALS",
          field: "stateCode",
          resolveValue: true,
          valueFieldName: "stateCode",
        },
        dependentFields: [
          {
            lookupFieldName: "id",
            fieldName: "stateCode",
          },
        ],
        props: {
          width: "15vw",
        },
      },
      {
        name: "stateCode",
        label: "State Code",
        type: "text",
        defaultValue: "",
        autoFilled: true,
        allowNull: true,
        props: {
          width: "5vw",
        },
      },
    ],
  },
  {
    name: "section1",
    type: "formSection",
    childFields: [
      {
        name: "virtualLocator",
        label: "Virtual Locator",
        type: "text",
        defaultValue: "",
        allowUpdate: false,
        props: {
          width: "15vw",
        },
      },
      {
        name: "projectCode",
        label: "Project Code",
        type: "text",
        autoFilled: true,
        allowNull: true,
        props: {
          width: "5vw",
        },
      },
    ],
  },
  {
    name: "section1",
    type: "formSection",
    childFields: [
      {
        name: "companyCode",
        label: "Company Code",
        type: "text",
        autoFilled: true,
        allowNull: true,
        props: {
          width: "5vw",
        },
      },
      {
        name: "section1",
        type: "formSection",
        childFields: [
          {
            name: "companyName",
            label: "Company Name",
            type: "lookup",
            lookupType: "company",
            allowUpdate: false,
            lookupFilter: {
              type: "IN",
              field: "ownerFlg",
              values: [true],
            },
            dependentFields: [
              {
                lookupFieldName: "id",
                fieldName: "companyCode",
              },
            ],
            props: {
              width: "15vw",
            },
          },
        ],
      },
    ],
  },
  {
    name: "oprojectName",
    label: "Old Project Name",
    type: "text",
    autoFilled: true,
    allowNull: true,
  },
  {
    name: "section1",
    type: "formSection",
    childFields: [
      {
        name: "active",
        label: "Active",
        type: "checkbox",
        defaultValue: false,
        allowUpdate: true,
      },
      {
        name: "closed",
        label: "Closed",
        type: "checkbox",
        allowUpdate: false,
        autoFilled: true,
        defaultValue: false,
      },
    ],
  },
];

export const projectColumns = [
  {
    name: "projectCode",
    label: "Project Code",
    type: "text",
  },
  {
    name: "projectName",
    label: "Project Name",
    type: "text",
  },
  {
    name: "projectType",
    label: "Project Type",
    type: "text",
    format: (value) => translatePicklistValue(value, projectTypeValues),
  },
  {
    name: "stateName",
    label: "State Name",
    type: "text",
  },
  {
    name: "virtualLocator",
    label: "Virtual Locator",
    type: "text",
  },
  {
    name: "routeLength",
    label: "Route Length (Mtr)",
    type: "number",
  },
  {
    name: "active",
    label: "Active",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
  {
    name: "closed",
    label: "Closed",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
  {
    name: "id",
    label: "Project Id",
    type: "text",
  },
];

export const projectExcelColumns = [
  {
    name: "projectCode",
    label: "Project Code",
    type: "text",
  },
  {
    name: "projectName",
    label: "Project Name",
    type: "text",
  },
  {
    name: "projectType",
    label: "Project Type",
    type: "text",
    format: (value) => translatePicklistValue(value, projectTypeValues),
  },
  {
    name: "stateName",
    label: "State Name",
    type: "text",
  },
  {
    name: "virtualLocator",
    label: "Virtual Locator",
    type: "text",
  },
  {
    name: "companyName",
    label: "Company Name",
    type: "text",
  },
  {
    name: "routeLength",
    label: "Route Length (Mtr)",
    type: "number",
  },
  {
    name: "active",
    label: "Active",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
  {
    name: "closed",
    label: "Closed",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
  {
    name: "id",
    label: "Project Id",
    type: "text",
  },
];

export const projectSearchColumns = [
  {
    name: "projectCode",
    label: "Project Code",
    type: "text",
  },
  {
    name: "projectName",
    label: "Project Name",
    type: "text",
  },
  {
    name: "projectType",
    label: "Project Type",
    type: "picklist",
    defaultValue: "",
    values: [
      { value: "FF", label: "FF Work" },
      { value: "FTTH", label: "FTTH Work" },
    ],
  },
  {
    name: "stateName",
    label: "State Name",
    type: "text",
  },
  {
    name: "virtualLocator",
    label: "Virtual Locator",
    type: "text",
  },
  {
    name: "active",
    label: "Active",
    type: "picklist",
    defaultValue: "",
    values: [
      { value: true, label: "True" },
      { value: false, label: "False" },
    ],
  },
  {
    name: "closed",
    label: "Closed",
    type: "picklist",
    defaultValue: "",
    values: [
      { value: true, label: "True" },
      { value: false, label: "False" },
    ],
  },
];


