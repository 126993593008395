import { useState, useEffect } from "react";
import {
  getDefaultValuesForRecordType,
  getFieldsForRecordType,
  getLeafFieldsForRecordType,
  getPageTitle,
} from "../../services/standardEntityFieldService";
import { apiCall } from "../../services/api";
import { useHistory, useParams } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Paper from "@mui/material/Paper";
import ConfirmationButtonComponent from "../buttons/ConfirmationButtonComponent";
import { transactionEntities } from "../../services/standardEntityFieldService";
import CircularProgress from "@mui/material/CircularProgress";
import withAuth from "../../hocs/withAuth";
import { Link } from "react-router-dom";
import _ from "lodash";
import DeleteButtonComponent from "../buttons/DeleteButtonComponent";
import { FormInputField } from "../inputFields/FormInputField";
import { isValidRecord } from "../../services/utils";
import { canEditEntity } from "../../services/utils";
import { Field } from "../../types/field";

function RecordAdditionForm({
  fields = [],
  formMinWidth = "38vw",
  entityType,
  isValidEntityRecord = () => {
    return true;
  },
  handleEntitySpecificChange = async (params) => {},
  handleEntityDecoration = (record) => {
    return record;
  },
  isUnSubmitRequired = (isApproved) => {
    return isApproved;
  },
  formDisabled = (record) => false,
  canDelete = (recordType, currentUser, record) => !transactionEntities.includes(recordType),
  pageTitle = "",
  currentUser,
}) {
  let { id, recordType }: any = useParams();
  recordType = entityType ? entityType : recordType;
  if (_.isEmpty(fields)) {
    fields = getFieldsForRecordType(recordType);
  }

  let defaultValues = getDefaultValuesForRecordType(recordType);
  const [error, setError] = useState(null);
  const [record, setRecord] = useState({ ...defaultValues, id: null, cancelled: false });
  const [formErrors, setFormErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const history = useHistory();

  const isUpdateForm = id != null;

  if (_.isEmpty(pageTitle)) {
    pageTitle = getPageTitle(recordType);
  }

  useEffect(() => {
    if (!canEditEntity(recordType, currentUser)) {
      history.push("/");
    }
  }, [recordType, currentUser, history]);

  useEffect(() => {
    if (_.isEmpty(id)) {
      return;
    }
    fetchRecord();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, recordType]);

  function fetchRecord() {
    setLoading(true);
    apiCall("get", `/api/entity/${recordType}/${id}`)
      .then((response) => {
        if (response == null) {
          console.log("Null response for records fetch");
          return;
        }
        setRecord({ ...response });
        setIsApproved(response.approved);
      })
      .catch((err) => {
        console.log("Error fetching records", err);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  async function handleChange(e, field:Field, handleRecordChange) {
    const { name, value, type, checked }: any = { ...e.target };
    setIsEdited(true);
    await handleEntitySpecificChange({
      record: { ...record },
      name,
      value,
      type,
      checked,
      setFormErrors,
      setError,
      handleRecordChange,
    });
  }

  const handleSubmit = (record) => {
    if (!isValidateRecord(record)) {
      return;
    }

    if (isUpdateForm) {
      updateRecord(record);
    } else {
      saveNewRecord(record);
    }
  };

  const isValidateRecord = (record) => {
    return isValidRecord({
      record,
      leafFields: getLeafFieldsForRecordType(recordType),
      setError,
      setFormErrors,
      isValidEntityRecord,
    });
  };

  const handleUnapprove = (record) => {
    apiCall("put", `/api/entity/${recordType}/${record.id}/toggle?approved=false`, record)
      .then((response) => {
        console.log(response);
        history.push(`/${recordType}/view/${id}`);
      })
      .catch((err) => {
        console.log("error inside unapproval", err);
        setError(err.message || err);
      });
  };

  function updateRecord(record) {
    let finalRecord = handleEntityDecoration(record);
    apiCall("put", `/api/entity/${recordType}/${record.id}`, finalRecord)
      .then((response) => {
        console.log(response);
        history.push(`/${recordType}/view/${id}`);
      })
      .catch((err) => {
        console.log("error inside calculator", err);
        setError(err.message || err);
      });
  }

  function saveNewRecord(record) {
    let finalRecord = handleEntityDecoration(record);
    console.log("final record", finalRecord);
    apiCall("post", `/api/entity/${recordType}/`, finalRecord)
      .then((response) => {
        console.log(response);
        let { name } = response;
        let id = response.id;
        setSuccessMessage(`Added ${name} Successfully`);
        history.push(`/${recordType}/view/${id}`);
      })
      .catch((err) => {
        console.log("error inside calculator", err);
        setError(err.message || err);
      });
  }

  const handleDeleteRecord = () => {
    apiCall("delete", `/api/entity/${recordType}/${record.id}`)
      .then((response) => {
        console.log(response);
        history.push(`/${recordType}/view`);
      })
      .catch((err) => {
        console.log("error inside calculator", err);
        setError(err.message || err);
      });
  };

  let globalConstants = { ...record };
  //todo: govern this by global constants kind of structure
  if (recordType === "material-inward") {
    globalConstants["invByBasic"] = 1;
    if (record && record["inventoryValue"] && record["basicValue"]) {
      let dividend = parseFloat(record["inventoryValue"]);
      let divisor = parseFloat(record["basicValue"]);
      if (!isNaN(dividend) && !isNaN(divisor) && divisor !== 0) {
        globalConstants["invByBasic"] = dividend / divisor;
      }
    }
  }

  const handleKeyPress = (event) => {
    try {
      if (event.key === "Enter") {
        var form = event.target.form;
        var index = Array.prototype.indexOf.call(form, event.target);
        form.elements[index + 2].focus();
        event.preventDefault();
      }
    } catch (e) {
      console.log("Exception occured while handling key press", e);
    }
  };

  return (
    <div>
      <Box sx={{ marginTop: 1.5 }}>
        <form onKeyDown={handleKeyPress}>
          <Stack direction="column" alignItems="center" spacing={2}>
            <h3 className="sub-header"> {isUpdateForm ? `Update ${pageTitle}` : `Add ${pageTitle}`}</h3>
            <Paper
              sx={{
                minWidth: formMinWidth,
                minHeight: "82vh",
                backgroundColor: "#F7F6FF",
              }}
              className="flexPaper"
              elevation={10}
            >
              <Stack sx={{ mt: 4, mb: 4 }} direction="column" alignItems="center" spacing={2}>
                {loading ? (
                  <Box sx={{ ml: 22, display: "flex" }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  fields.map((field, index) => (
                    <FormInputField
                      key={index}
                      field={field}
                      leafFields={getLeafFieldsForRecordType(recordType)}
                      record={record}
                      setRecord={setRecord}
                      isUpdateForm={isUpdateForm}
                      formDisabled={formDisabled(record)}
                      formApproved={isUnSubmitRequired(isApproved)}
                      formCancelled={record.cancelled}
                      handleChangeInternal={handleChange}
                      formErrors={formErrors}
                      setFormErrors={setFormErrors}
                      entityType={recordType}
                      setError={setError}
                      globalConstants={globalConstants}
                    />
                  ))
                )}
              </Stack>
            </Paper>
            {error && (
              <div style={{ width: formMinWidth, justifyContent: "center" }} className="alert alert-danger">
                {error}
              </div>
            )}
            {!loading && (
              <Stack direction="row" alignItems="center" spacing={2}>
                {!isEdited ? (
                  <Button variant="contained" color="error" component={Link} to={`/${recordType}/view`}>
                    Back
                  </Button>
                ) : (
                  <ConfirmationButtonComponent
                    handleSubmit={() => (isUpdateForm ? history.push(`/${recordType}/view/${id}`) : history.push(`/${recordType}/view/`))}
                    confirmationTitle="Back"
                    confirmationBody={`Are You Sure,  All Your Unsaved Changes Will Be Lost`}
                    submitBody={record}
                    buttonText={"Back"}
                    color="error"
                  />
                )}
                {isUpdateForm && canDelete(recordType, currentUser, record) && (
                  <DeleteButtonComponent handleDelete={handleDeleteRecord} itemType={recordType} />
                )}
                {isUnSubmitRequired(isApproved) ? (
                  <ConfirmationButtonComponent
                    handleSubmit={handleUnapprove}
                    confirmationTitle="UnSubmit"
                    confirmationBody="Are You Sure You Wish To Un-Submit/Un-Approve"
                    submitBody={record}
                    buttonText={"UnSubmit"}
                  />
                ) : (
                  <ConfirmationButtonComponent
                    handleSubmit={handleSubmit}
                    confirmationTitle="Save Record"
                    confirmationBody="Are You Sure You Wish To Proceed?"
                    submitBody={record}
                    buttonText={isUpdateForm ? "Update" : "Save"}
                    disabled={!isEdited}
                    shouldOpen={isValidateRecord}
                  />
                )}
              </Stack>
            )}
          </Stack>
          <h4> </h4>
        </form>

        {successMessage !== null && (
          <Alert severity="success">
            <AlertTitle>Success</AlertTitle>
            {successMessage}
          </Alert>
        )}
      </Box>
    </div>
  );
}

export default withAuth(RecordAdditionForm);
