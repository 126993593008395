import _ from "lodash";

export function evaluateFilter(filter, record) {
  let { field, type, values, filters } = filter;
  console.log("filter is", filter);
  console.log("record is", record[field]);
  console.log("values is", values);
  if (!record) {
    return false;
  }
  switch (type) {
    case "NOT":
      let andFilter = { type: "AND", filters };
      return !evaluateFilter(andFilter, record);
    case "AND":
      if (!filters) {
        return true;
      }
      for (let filter of filters) {
        if (!evaluateFilter(filter, record)) {
          return false;
        }
      }
      return true;
    case "OR":
      console.log("or filters are ", filters);
      if (!filters) {
        console.log("or filters not filters are ", filters);
        return true;
      }
      for (let filter of filters) {
        console.log("or filters internal filter is", filter);
        console.log("or filters internal filter is", evaluateFilter(filter, record));
        if (evaluateFilter(filter, record)) {
          console.log("or filters evaluateFilter true", filter);
          return true;
        }
      }
      return false;
    case "EQUALS":
      return record[field] === values;
    case "NOT_EQUALS":
      return record[field] !== values;
    case "CONTAINS":
      return record[field].toLowerCase().includes(values.toLowerCase());
    case "IN":
      console.log("or filters are [in] ", values.includes(record[field]));
      // eslint-disable-next-line eqeqeq
      return values.some((value) => value == record[field]);
    case "NIN":
      // eslint-disable-next-line eqeqeq
      return !values.some((value) => value == record[field]);
    case "MISSING":
      return !record[field];
    case "EXISTS":
      return record[field] && !_.isEmpty(record[field]);
    default:
      throw new Error(`Unsupported filter type: ${type}`);
  }
}
