import { Switch, Route, withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Homepage from "../components/Homepage";
import AuthForm from "../components/auth/AuthForm";
import { authUser, logout } from "../store/actions/auth";
import { removeError } from "../store/actions/errors";
import UserViewForm from "../components/users/UserViewForm";
import RecordAdditionForm from "../components/recordStandardForms/RecordAdditionForm";
import MaterialInwardForm from "../components/transactionEntities/materialInward/MaterialInwardForm";
import MaterialOutwardForm from "../components/transactionEntities/materialOutward/MaterialOutwardForm";
import RecordFullViewForm from "../components/recordStandardForms/RecordFullViewForm";
import MaterialInwardUpdationForm from "../components/transactionEntities/materialInward/MaterialInwardUpdationForm";
import RecordManager from "../components/recordStandardForms/RecordManager";
import StockReportForm from "../components/reports/StockReportForm";
import StockAgeingForm from "../components/reports/StockAgeingForm";
import StockOpeningClosingForm from "../components/reports/StockOpeningClosingForm";
import ConfirmLogin from "../components/auth/ConfirmLogin";
import VerifyTFAForm from "../components/auth/VerifyTFAForm";
import UserAdditionForm from "../components/users/UserAdditionForm";
import GatePassForm from "../components/transactionEntities/gatePass/GatePassForm";
import MBSheetForm from "../components/transactionEntities/mbSheet/MBSheetForm";
import StockInOutForm from "../components/reports/StockInOutForm";

const Main = (props) => {
  const { authUser, errors, removeError, currentUser, logout } = props;
  return (
    <div className="container">
      <Switch>
        <Route exact path="/" render={() => <Homepage currentUser={currentUser} />} />
        <Route
          exact
          path="/login"
          render={(props) => {
            return <AuthForm removeError={removeError} errors={errors} onAuth={authUser} buttonText="Login" heading="Sign In" {...props} />;
          }}
        />
        <Route
          exact
          path="/signup"
          render={(props) => {
            return (
              <AuthForm
                removeError={removeError}
                errors={errors}
                onAuth={authUser}
                signUp
                buttonText="Sign Up"
                heading="Sign Up"
                logout={logout}
                {...props}
              />
            );
          }}
        />
        <Route path="/user/view" render={() => <UserViewForm />} />
        <Route path="/my-profile/view" render={() => <UserViewForm />} />
        <Route path="/gate-pass/add" render={() => <GatePassForm />} />
        <Route path="/mb-sheet/add" render={() => <MBSheetForm />} />
        <Route path="/mb-sheet/update/:id" render={() => <MBSheetForm />} />
        <Route path="/material-inward/add" render={() => <MaterialInwardForm />} />
        <Route path="/material-inward/update/:id" render={() => <MaterialInwardUpdationForm />} />
        <Route path="/material-outward/add" render={() => <MaterialOutwardForm />} />
        <Route path="/material-outward/update/:id" render={() => <MaterialOutwardForm />} />
        <Route path="/user/update/:id" render={() => <UserAdditionForm />} />
        <Route path="/:recordType/view/:id" render={() => <RecordFullViewForm />} />
        <Route path="/:recordType/view" render={() => <RecordManager />} />
        <Route path="/:recordType/update/:id" render={() => <RecordAdditionForm />} />
        <Route path="/:recordType/add" render={() => <RecordAdditionForm />} />
        <Route path="/report/stock" render={() => <StockReportForm />} />
        <Route path="/report/stock-ageing" render={() => <StockAgeingForm />} />
        <Route path="/report/stock-opening-closing" render={() => <StockOpeningClosingForm />} />
        <Route path="/report/stock-in-out" render={() => <StockInOutForm />} />
        <Route path="/confirmLogin" render={() => <ConfirmLogin onAuth={authUser} />} />
        <Route path="/verifyTFA/:userId" render={() => <VerifyTFAForm onAuth={authUser} />} />
        <Redirect to="/" />
      </Switch>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    currentUser: state.currentUser,
    errors: state.errors,
  };
}

export default withRouter(connect(mapStateToProps, { authUser, removeError, logout })(Main));
