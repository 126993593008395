import React, { useState } from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { isEmptyObj } from "../../services/utils";
import { useParams } from "react-router-dom";

const VerifyTFAForm = ({ onAuth, currentUser }) => {
  const history = useHistory();
  const [userPin, setUserPin] = useState("");
  const [formError, setFormError] = useState("");

  let { userId } = useParams<{ userId: string }>();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await onAuth("verifyTFA", { userPin });
      history.push("/");
    } catch (e) {
      setFormError(e.message);
      return;
    }
  };

  const handleChange = (e) => {
    setUserPin(e.target.value);
    setFormError("");
  };

  return (
    <Box component="span" sx={{ p: 10, m: 5 }}>
      <div className="row justify-content-md-center text-center">
        <div className="col-md-6">
          <form onSubmit={handleSubmit}>
            <Stack direction="column" alignItems="center" spacing={4}>
              <h2>Two Factor Verification</h2>
              <Paper sx={{ m: 5 }} elevation={10}>
                <Stack sx={{ m: 5, mt: 5 }} direction="column" alignItems="center" spacing={2}>
                  {userId && (
                    <TextField
                      id="userId"
                      name="userId"
                      label="User Id"
                      disabled={true}
                      type="text"
                      required
                      sx={{ m: 2, width: "30ch" }}
                      value={userId}
                    />
                  )}
                  <TextField
                    id="userPin"
                    name="userPin"
                    label="Pin"
                    onChange={handleChange}
                    error={!isEmptyObj(formError)}
                    helperText={formError ? formError : null}
                    type="text"
                    required
                    sx={{ m: 2, width: "30ch" }}
                    value={userPin}
                  />
                </Stack>
              </Paper>
              <Stack direction="row" alignItems="center" spacing={2}>
                <Button variant="contained" color="error" component={Link} to={`/`}>
                  Cancel
                </Button>
                <Button disabled={isEmptyObj(userPin)} variant="contained" type="submit">
                  Submit
                </Button>
              </Stack>
            </Stack>
          </form>
        </div>
      </div>
    </Box>
  );
};

export default VerifyTFAForm;
