import { Field } from "../../../types/field";
import { getYesterday, getOneMonthAgo } from "../../../services/dateUtils";
import { materialTypeValues } from "../../../standardEntities/materialInwardFields";
import { stockTypeValues } from "../reportUtils";

export const stockOpeningClosingFormFields: Field[] = [
  {
    name: "sections",
    type: "formSection",
    childFields: [
      {
        name: "docLocatorName",
        label: "Inward Locator Name",
        allowNull: true,
        props: {
          width: "21vw",
        },
        type: "lookup",
        lookupType: "locator",
        lookupFieldName: "locatorCode",
        lookupCategory: "docLocator",
        lookupParams: [
          {
            key: "projectCode",
            resolveValue: true,
            valueFieldName: "projectCode",
          },
          {
            key: "docCompanyCode",
            resolveValue: true,
            valueFieldName: "docCompanyCode",
          },
        ],
        lookupFilter: {
          type: "AND",
          filters: [
            {
              type: "EQUALS",
              field: "locatorType",
              values: ["MS", "CS"],
            },
            {
              type: "EQUALS",
              field: "locatorCode",
              resolveValue: true,
              valueFieldName: "docLocatorCode",
            },
          ],
        },
        dependentFields: [
          {
            lookupFieldName: "id",
            fieldName: "docLocatorCode",
          },
          {
            additionalFieldName: "details",
            lookupFieldName: "locatorType",
            fieldName: "docLocatorType",
          },
          {
            additionalFieldName: "company",
            lookupFieldName: "companyCode",
            fieldName: "docCompanyCode",
          },
          {
            additionalFieldName: "company",
            lookupFieldName: "companyName",
            fieldName: "docCompanyName",
          },
          {
            additionalFieldName: "contractor",
            lookupFieldName: "companyCode",
            fieldName: "docContractorCode",
          },
          {
            additionalFieldName: "contractor",
            lookupFieldName: "companyName",
            fieldName: "docContractorName",
          },
        ],
      },
    ],
  },
  {
    name: "sections",
    type: "formSection",
    childFields: [
      {
        name: "docLocatorCode",
        label: "Locator",
        type: "text",
        allowNull: true,
        props: {
          width: "6vw",
        },
        autoFilled: true,
      },
      {
        name: "docCompanyName",
        label: "Inward Company Name",
        type: "text",
        autoFilled: true,
        displayOnly: true,
        allowNull: true,
        props: {
          width: "14vw",
        },
      },
    ],
  },
  {
    name: "section1",
    type: "formSection",
    childFields: [
      {
        name: "contractorName",
        label: "Contractor Name",
        type: "lookup",
        lookupType: "company",
        lookupCategory: "workClosureContractor",
        lookupFilter: { type: "AND", filters: [{ type: "EQUALS", field: "contractorFlg", values: [true] }] },
        props: {
          width: "14vw",
        },
        dependentFields: [
          {
            lookupFieldName: "id",
            fieldName: "contractorCode",
          },
        ],
        allowNull: true,
      },
      {
        name: "contractorCode",
        label: "Contractor Code",
        type: "text",
        props: {
          width: "6vw",
        },
        autoFilled: true,
        allowNull: false,
      },
    ],
  },
  {
    name: "sections",
    type: "formSection",
    childFields: [
      {
        name: "projectName",
        label: "Project Name",
        type: "lookup",
        lookupType: "project",
        lookupCategory: "docProject",
        allowNull: true,
        props: {
          width: "21vw",
        },
        lookupFilter: {
          type: "AND",
          filters: [
            {
              type: "EQUALS",
              field: "projectCode",
              resolveValue: true,
              valueFieldName: "projectCode",
            },
          ],
        },
        lookupParams: [
          {
            key: "docLocatorCode",
            resolveValue: true,
            valueFieldName: "docLocatorCode",
          },
          {
            key: "docCompanyCode",
            resolveValue: true,
            valueFieldName: "docCompanyCode",
          },
        ],
        dependentFields: [
          {
            lookupFieldName: "id",
            fieldName: "projectCode",
          },
        ],
      },
    ],
  },
  {
    name: "sections",
    type: "formSection",
    childFields: [
      {
        name: "materialType",
        label: "Material Type",
        type: "picklist",
        allowNull: true,
        values: materialTypeValues,
        props: {
          width: "10vw",
        },
      },
      {
        name: "projectCode",
        label: "Code",
        type: "text",
        autoFilled: true,
        allowNull: true,
        defaultValue: null,
        props: {
          width: "10vw",
        },
      },
    ],
  },
  {
    name: "sections",
    type: "formSection",
    childFields: [
      {
        name: "itemName",
        label: "Item Name",
        type: "lookup",
        lookupType: "item",
        lookupFieldName: "itemName",
        allowNull: true,
        props: {
          width: "21vw",
        },
        lookupFilter: {
          type: "AND",
          filters: [
            {
              type: "EQUALS",
              field: "itemCode",
              resolveValue: true,
              valueFieldName: "itemCode",
            },
            {
              type: "EQUALS",
              field: "itemGroupId",
              resolveValue: true,
              valueFieldName: "itemGroupId",
            },
          ],
        },
        dependentFields: [
          {
            lookupFieldName: "id",
            fieldName: "itemCode",
          },
          {
            additionalFieldName: "details",
            lookupFieldName: "itemCustCode",
            fieldName: "itemCustCode",
          },
        ],
      },
    ],
  },
  {
    name: "sections",
    type: "formSection",
    childFields: [
      {
        name: "stockType",
        label: "Stock Type",
        type: "picklist",
        defaultValue: "P",
        values: stockTypeValues,
        props: {
          width: "10vw",
        },
      },
      {
        name: "itemCustCode",
        label: "Part Code",
        type: "text",
        allowNull: true,
        autoFilled: true,
        props: {
          width: "10vw",
        },
      },
    ],
  },
  {
    name: "sections",
    type: "formSection",
    childFields: [
      {
        name: "sections",
        type: "formSection",
        childFields: [
          {
            name: "stockOpeningDate",
            label: "Stock Opening Date",
            type: "date",
            defaultValue: getOneMonthAgo(),
            constraints: [
              {
                type: "DATE_RANGE",
                minDateScript: {
                  globalFields: ["openingStockDate"],
                },
                maxDateScript: {
                  valueFields: ["stockClosingDate"],
                },
                beforeNow: true,
              },
            ],
          },
        ],
      },
      {
        name: "sections",
        type: "formSection",
        childFields: [
          {
            name: "stockClosingDate",
            label: "Stock Closing Date",
            type: "date",
            defaultValue: getYesterday(),
            constraints: [
              {
                type: "DATE_RANGE",
                minDateScript: {
                  operation: "minDate",
                  valueFields: ["stockOpeningDate"],
                  globalFields: ["openingStockDate"],
                },
                beforeNow: true,
              },
            ],
          },
        ],
      },
    ],
  },
];
