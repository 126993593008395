import withAuth from "../../hocs/withAuth";
import GenerateReportForm from "./GenerateReportForm";
import { stockOpenCloseAvailableColumns, stockOpenClosePhysicalColumns } from "../../standardEntities/stockView";
import { translatePicklistValue } from "../../services/fieldsUtils";
import { groupByValues, stockInOutFields } from "./formFields/stockInOutForms";

const getExcelColumns = (searchRequest) => {
  return reportCategoryToExcelColumns[searchRequest.stockType];
};

const reportCategoryToExcelColumns = {
  A: stockOpenCloseAvailableColumns,
  P: stockOpenClosePhysicalColumns,
};

const StockReportForm = ({ formMinWidth = "38vw" }) => {
  return (
    <GenerateReportForm
      formMinWidth={formMinWidth}
      fields={stockInOutFields}
      getExcelColumns={getExcelColumns}
      pageTitle={"Stock In Out Report"}
      getReportName={(searchRequest) => `Stock In Out Report (${translatePicklistValue(searchRequest.groupBy, groupByValues)})`}
      reportType={"stock-in-out"}
    />
  );
};

export default withAuth(StockReportForm);
