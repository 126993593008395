import { Field } from "../types/field";
import { translatePicklistValue } from "../services/fieldsUtils";

export const projectTypeValues = [
  { value: "FF", label: "FF Work" },
  { value: "FTTH", label: "FTTH Work" },
];

export const jobTypeValues = [
  { value: "BBW", label: "Back Bone" },
  { value: "LMW", label: "Last Mile" },
  { value: "ICW", label: "Inter City" },
  { value: "FTTB", label: "FTTB" },
  { value: "OTH", label: "Others" },
];

export const jobFields: Field[] = [
  {
    name: "projectName",
    label: "Project Name",
    type: "lookup",
    lookupType: "project",
    lookupCategory: "workProjects",
    lookupFilter: {
      type: "AND",
      filters: [
        {
          type: "EQUALS",
          field: "stateCode",
          resolveValue: true,
          valueFieldName: "locatorStateCode",
        },
        {
          type: "IN",
          field: "projectType",
          values: ["FF"],
        },
      ],
    },
    dependentFields: [
      {
        lookupFieldName: "id",
        fieldName: "projectCode",
      },
      {
        additionalFieldName: "details",
        lookupFieldName: "stateCode",
        fieldName: "stateCode",
      },
      {
        additionalFieldName: "details",
        lookupFieldName: "stateName",
        fieldName: "stateName",
      },
      {
        additionalFieldName: "details",
        lookupFieldName: "projectType",
        fieldName: "projectType",
      },
    ],
  },
  {
    name: "section1",
    type: "formSection",
    childFields: [
      {
        name: "projectType",
        label: "Project Type",
        type: "picklist",
        defaultValue: "",
        autoFilled: true,
        values: [
          { value: "FF", label: "FF Work" },
          { value: "FTTH", label: "FTTH Work" },
        ],
        props: {
          width: "14vw",
        },
      },
      {
        name: "projectCode",
        label: "Project Code",
        type: "text",
        autoFilled: true,
        props: {
          width: "6vw",
        },
      },
    ],
  },
  {
    name: "section1",
    type: "formSection",
    childFields: [
      {
        name: "jobType",
        label: "Job Type",
        type: "picklist",
        allowUpdate: true,
        defaultValue: "",
        values: [
          { value: "BBW", label: "Back Bone" },
          { value: "LMW", label: "Last Mile" },
          { value: "ICW", label: "Inter City" },
          { value: "FTTB", label: "FTTB" },
          { value: "OTH", label: "Others" },
        ],
      },
      {
        name: "stateName",
        label: "State Name",
        type: "text",
        autoFilled: true,
        allowNull: true,
      },
    ],
  },
  {
    name: "siteId",
    label: "Site Id",
    type: "text",
    defaultValue: "",
  },
  {
    name: "section1",
    type: "formSection",
    childFields: [
      {
        name: "id",
        label: "Job Id",
        type: "number",
      },
      {
        name: "poNo",
        label: "PO number",
        type: "number",
        allowUpdate: true,
        defaultValue: "",
      },
    ],
  },
  {
    name: "section1",
    type: "formSection",
    childFields: [
      {
        name: "contractorName",
        label: "Contractor Name",
        type: "lookup",
        lookupType: "company",
        lookupCategory: "contractorCompany",
        lookupFilter: { type: "AND", filters: [{ type: "EQUALS", field: "contractorFlg", values: [true] }] },
        props: {
          width: "14vw",
        },
        dependentFields: [
          {
            lookupFieldName: "id",
            fieldName: "contractorCode",
          },
        ],
      },
      {
        name: "contractorCode",
        label: "Contractor Code",
        type: "text",
        props: {
          width: "6vw",
        },
        autoFilled: true,
        allowNull: true,
      },
    ],
  },
  {
    name: "section4",
    type: "formSection",
    childFields: [
      {
        name: "active",
        label: "Active",
        type: "checkbox",
        allowUpdate: true,
        defaultValue: false,
      },
      {
        name: "closed",
        label: "Closed",
        type: "checkbox",
        defaultValue: false,
        allowUpdate: false,
        displayOnly: true,
      },
    ],
  },
];

export const jobColumns = [
  {
    name: "id",
    label: "Job Id",
    type: "text",
  },
  {
    name: "siteId",
    label: "Site Id",
    type: "text",
  },
  {
    name: "jobType",
    label: "Job Type",
    type: "text",
    format: (value) => translatePicklistValue(value, jobTypeValues),
  },
  {
    name: "poNo",
    label: "PO Number",
    type: "text",
  },
  {
    name: "projectCode",
    label: "Project Code",
    type: "text",
  },
  {
    name: "projectName",
    label: "Project Name",
    type: "text",
  },
  {
    name: "stateName",
    label: "State Name",
    type: "text",
  },
  {
    name: "contractorName",
    label: "Contractor Name",
    type: "text",
  },
  {
    name: "active",
    label: "Active",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
  {
    name: "closed",
    label: "Closed",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
];

export const jobExcelColumns = [
  {
    name: "id",
    label: "Job Id",
    type: "text",
  },
  {
    name: "siteId",
    label: "Site Id",
    type: "text",
  },
  {
    name: "jobType",
    label: "Job Type",
    type: "text",
    format: (value) => translatePicklistValue(value, jobTypeValues),
  },
  {
    name: "poNo",
    label: "PO Number",
    type: "text",
  },
  {
    name: "projectCode",
    label: "Project Code",
    type: "text",
  },
  {
    name: "projectName",
    label: "Project Name",
    type: "text",
  },
  {
    name: "projectType",
    label: "Project Type",
    type: "text",
    format: (value) => translatePicklistValue(value, projectTypeValues),
  },
  {
    name: "stateName",
    label: "State Name",
    type: "text",
  },
  {
    name: "virtualLocator",
    label: "Virtual Locator",
    type: "text",
  },
  {
    name: "routeLength",
    label: "Project Route (Mtr)",
    type: "number",
  },
  {
    name: "contractorCode",
    label: "Contractor Code",
    type: "text",
  },
  {
    name: "contractorName",
    label: "Contractor Name",
    type: "text",
  },
  {
    name: "active",
    label: "Active",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
  {
    name: "closed",
    label: "Closed",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
];

export const jobSearchColumns = [
  {
    name: "jobId",
    label: "Job Id",
    type: "text",
  },
  {
    name: "siteId",
    label: "Site Id",
    type: "text",
  },
  {
    name: "jobType",
    label: "Job Type",
    type: "picklist",
    defaultValue: "",
    values: [
      { value: "BBW", label: "Back Bone" },
      { value: "LMW", label: "Last Mile" },
      { value: "ICW", label: "Inter City" },
      { value: "FTTB", label: "FTTB" },
      { value: "OTH", label: "Others" },
    ],
  },
  {
    name: "poNo",
    label: "PO number",
    type: "text",
  },
  {
    name: "job.projectCode",
    label: "Project Code",
    type: "text",
  },
  {
    name: "projectName",
    label: "Project Name",
    type: "text",
  },
  {
    name: "stateName",
    label: "State Name",
    type: "text",
  },
  {
    name: "companyName",
    label: "Contractor",
    type: "text",
  },
  {
    name: "active",
    label: "Active",
    type: "picklist",
    defaultValue: "",
    values: [
      { value: true, label: "True" },
      { value: false, label: "False" },
    ],
  },
  {
    name: "closed",
    label: "Closed",
    type: "picklist",
    defaultValue: "",
    values: [
      { value: true, label: "True" },
      { value: false, label: "False" },
    ],
  },
];
