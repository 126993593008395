import React from "react";
import withAuth from "../../../hocs/withAuth";
import _ from "lodash";
import RecordAdditionForm from "../../recordStandardForms/RecordAdditionForm";
function MaterialOutwardForm() {
  const isValidRecord = (record, setFormErrors, setError) => {
    console.log("record", record);
    if (!record) {
      return false;
    }
    if (!record.details || _.isEmpty(record.details)) {
      return true;
    }
    let mirAvailableQuantitesValid = true;
    record.details.forEach((detail, i) => {
      if (parseFloat(detail.mirAvailableQuantity) < parseFloat(detail.totalQuantity)) {
        setError(
          `Selected Quantity: ${parseFloat(detail.totalQuantity)} is greater than available quantity: ${parseFloat(
            detail.mirAvailableQuantity
          )} for MIR Row Id: ${detail.mirRowId}`
        );
        mirAvailableQuantitesValid = false;
        setFormErrors((formError) => {
          formError["details"] = formError["details"] ? formError["details"] : [];
          formError["details"][i] = formError["details"][i] ? formError["details"][i] : {};
          formError["details"][i]["totalQuantity"] = "Error";
          return { ...formError };
        });
        return;
      }
    });
    console.log("debug error MaterialOutwardForm returning true", mirAvailableQuantitesValid);
    if (mirAvailableQuantitesValid) {
      setError(null);
    }
    return mirAvailableQuantitesValid;
  };

  const handleEntitySpecificChange = async ({ record, name, value, type, checked, setFormErrors, setError, handleRecordChange }) => {
    if (name === "transactionType" && value === "CS" && record.projectType === "FTTH") {
      handleRecordChange({
        target: { name: "receiverDocType", value: "CO", type, checked },
      });
    } else if (name === "projectType" && record.transactionType === "CS" && value === "FTTH") {
      handleRecordChange({
        target: { name: "receiverDocType", value: "CO", type, checked },
      });
    }
    console.log("archit handleEntitySpecificChange record", name, value, type, record[name]);
    if (name === "details" && value.length === 1) {
      // let request = {
      //   query: "",
      //   filter: {},
      //   lookupCategory: "outwardItem",
      //   lookupParams: {
      //     docLocatorCode: record.docLocatorCode,
      //     materialType: record.materialType,
      //     projectCode: record.projectCode,
      //     selectedMirRowIds: [],
      //     outwardDocDate: record.docDate,
      //   },
      // };
      // let resp = await apiCall("post", `/api/entity/item/lookup`, request);
      // if (!resp || !resp.results || resp.results.length === 0) {
      //   setError("No valid item for outward found for the selected locator and project. Please select another locator or project. Or check the inward details are valid and approved/submitted.");
      // }
    }
  };

  return (
    <RecordAdditionForm
      formMinWidth={"80wv"}
      entityType="material-outward"
      isValidEntityRecord={isValidRecord}
      handleEntitySpecificChange={handleEntitySpecificChange}
      allowDelete={false}
    />
  );
}

export default withAuth(MaterialOutwardForm);
