import React, { useState, useMemo } from "react";
import { apiCall } from "../../services/api";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { debounce } from "@mui/material/utils";
import { getRecordsSearchRequestFilters, getRecordLookupParams } from "../../services/utils";
import { evaluateFilter } from "../../services/filterEvaluator";
import Snackbar from "@mui/material/Snackbar";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { LookupField as LookupFieldType } from "../../types/field";
// import { useQuery } from "react-query";

export default function LookupField(props) {
  let {
    key = 0,
    handleRecordSelection,
    value = null,
    width,
    field,
    record,
    size = "medium",
    disabled = false,
    parentGlobalConstants = {},
    error,
    cancelled = false,
  } = props;
  let fieldCopy: LookupFieldType = { ...field };
  let {
    name,
    label,
    lookupType,
    lookupCategory,
    lookupFieldName,
    lookupFilter,
    dependentFields = [],
    lookupParams,
    emptyResponseMessage,
    warningMessageConfig,
  } = fieldCopy;
  let request = {};

  value = value === "" || typeof value == "undefined" || value == null ? null : !isNaN(value) ? value.toString() : value;
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  // const [page, setPage] = useState(0); // Track the current page
  const [loading, setLoading] = useState(false);

  let globalConstants = { ...parentGlobalConstants };
  function getOptionsText() {
    //  if(!inputValue || inputValue.length < 3){
    //   return  "Type atleast 3 characters"
    // }
    if (loading) {
      return "Loading...";
    } else {
      return emptyResponseMessage ? emptyResponseMessage : "No match found";
    }
  }

  // move to a commmon service to reuse with record view form

  const handleSnackBarClose = () => {
    setSnackBarMessage(null);
  };

  function handleChange(event, newValue) {
    let fieldValue = newValue ? newValue.name : "";
    if (!isNaN(fieldValue)) {
      fieldValue = fieldValue.toString();
    }
    console.log("archit lookup new Value is (handle change) for name", fieldValue, name);

    handleRecordSelection({ target: { name, value: fieldValue } });
    let newRecord = changeFieldAndDependentValues(newValue, fieldValue);
    if (fieldValue === "" || fieldValue == null) {
      console.log("archit lookup params debug null handle change", newRecord);
      lookupValues("", newRecord);
    }
  }

  function changeFieldAndDependentValues(newValue, fieldValue) {
    let newRecord = { ...record };
    dependentFields.forEach((dependentField) => {
      let value = null;
      if (newValue && dependentField.lookupFieldName) {
        if (
          dependentField.additionalFieldName &&
          newValue.additional &&
          newValue.additional[dependentField.additionalFieldName] &&
          !(newValue.additional[dependentField.additionalFieldName][dependentField.lookupFieldName] == null)
        ) {
          value = newValue.additional[dependentField.additionalFieldName][dependentField.lookupFieldName];
        } else if (newValue[dependentField.lookupFieldName]) {
          value = newValue[dependentField.lookupFieldName];
        }
      }
      newRecord[dependentField.fieldName] = value;
      handleRecordSelection({ target: { name: dependentField.fieldName, value } });
    });

    if (warningMessageConfig && warningMessageConfig.filter && evaluateFilter(warningMessageConfig.filter, newRecord)) {
      setSnackBarMessage(warningMessageConfig.message);
    }
    return newRecord;
  }

  function handleInputChange(newInputValue) {
    console.log("archit lookup debug value in handleInputChange", newInputValue, value, newInputValue === value);
    if (newInputValue === value) {
      return;
    }
    setInputValue(newInputValue);
    lookupValues(newInputValue);
  }

  const fetch = useMemo(
    () =>
      debounce((request, callback) => {
        console.log("archit request is", request);
        setLoading(true);
        apiCall("post", `/api/entity/${lookupType}/lookup`, request)
          .then((response) => {
            console.log("archit respons is", response);

            if (response == null) {
              console.log("Null response for records fetch");
              return;
            }
            callback(response.results);
          })
          .catch((err) => {
            console.log("Error fetching records", err);
          })
          .finally(() => {
            setLoading(false);
          });
      }, 400),
    [lookupType]
  );

  function lookupValues(newInputValue = inputValue, newRecord = record) {
    let active = true;

    // if (inputValue.length <3) {
    //   setOptions(value ? [value] : []);
    //   return undefined;
    // }
    setLoading(true);
    try {
      fetchLookupValues(active, newInputValue, newRecord);
    } finally {
      setLoading(false);
    }

    return () => {
      active = false;
    };
  }

  function fetchLookupValues(active, query = inputValue, newRecord = record) {
    let filteredNewOptions = [];
    request = {
      query,
      filter: getRecordsSearchRequestFilters(newRecord, lookupFilter, globalConstants),
      lookupFieldName,
      lookupCategory,
      lookupParams: getRecordLookupParams(newRecord, lookupParams, globalConstants),
    };
    fetch(request, (results) => {
      console.log("results", results);
      if (active) {
        let newOptions = [];

        if (results) {
          newOptions = [...results];
        }
        console.log("newOptions", newOptions);
        console.log("newOptions value", value);
        // filteredNewOptions = newOptions.filter((option) => { return (!value || (option.id !== value.id)); });
        // console.log("filteredNewOptions", filteredNewOptions);
        setOptions(newOptions);
      }
    });

    return filteredNewOptions;
  }
  const action = (
    <React.Fragment>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackBarClose}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  return (
    <>
      <Autocomplete
        id="record-search"
        key={key}
        sx={{ width: { width }, fontSize: "1rem" }}
        getOptionLabel={(option) => (typeof option === "string" ? option : option.name)}
        filterOptions={(x) => x}
        options={options}
        value={value}
        isOptionEqualToValue={(option, value) => option.name === value || option.name.toString() === value}
        autoComplete
        includeInputInList
        // filterSelectedOptions
        size={size}
        disabled={disabled}
        noOptionsText={getOptionsText()}
        onChange={(event, newValue) => {
          handleChange(event, newValue);
        }}
        onInputChange={(event, newInputValue) => {
          handleInputChange(newInputValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            fullWidth
            error={error}
            // helperText={helperText}
            onFocus={(e) => {
              lookupValues(inputValue);
            }}
            sx={{
              textDecoration: cancelled ? "line-through" : "none",
              color: cancelled ? "red" : "inherit",
            }}
          />
        )}
        renderOption={(props, option) => {
          return (
            <li {...props}>
              <Grid container alignItems="center">
                <Grid item sx={{ width: "calc(100% - 44px)", wordWrap: "break-word" }}>
                  {option.name}
                  <Typography variant="body2" color="text.secondary">
                    {option.id}
                    {option.description ? "," : ""} {option.description}
                  </Typography>
                </Grid>
              </Grid>
            </li>
          );
        }}
      />
      <Snackbar
        open={!!snackBarMessage}
        autoHideDuration={3000}
        onClose={handleSnackBarClose}
        message={snackBarMessage}
        action={action}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      />
    </>
  );
}
