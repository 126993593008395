import React from "react";
import withAuth from "../../../hocs/withAuth";
import _ from "lodash";
import RecordAdditionForm from "../../recordStandardForms/RecordAdditionForm";
import { openingStockDate } from "../../../constants/globalConstants";

function MaterialInwardForm() {
  const isValidRecord = (record, setFormErrors, setError) => {
    if (!record) {
      return false;
    }
    const totalDetailValues = _.isEmpty(record.details)
      ? 0
      : record.details
          .filter((detail) => !detail.deleted)
          .reduce((total, detail) => {
            return total + parseFloat(detail.basicAmount);
          }, 0);
    let isTotalValid = totalDetailValues === parseFloat(record.basicValue);
    if (!isTotalValid) {
      setFormErrors((formError) => {
        formError["basicValue"] = `Should match sum of all Basic Amounts: ${totalDetailValues}`;
        return { ...formError };
      });
      setError(`Basic value (${parseFloat(record.basicValue)}) does not match sum of all basic amounts in detail (${totalDetailValues})`);
      return false;
    } else {
      setFormErrors((formError) => {
        formError["inventoryValue"] = "";
        return { ...formError };
      });
      setError("");
    }
    return true;
  };

  const handleEntitySpecificChange = ({ record, name, value, type, checked, setFormErrors, handleRecordChange }) => {
    console.log("handleEntitySpecificChange type is", type);
    console.log("handleEntitySpecificChange name is", name);
    console.log("handleEntitySpecificChange value is", value);
    if (name === "transactionType") {
      console.log("handleEntitySpecificChange changin value to blank");
      handleRecordChange({
        target: { name: "senderLocatorCode", value: "", type, checked },
      });
      if (value === "OS") {
        handleRecordChange({
          target: { name: "docDate", value: openingStockDate, type, checked },
        });
      } else if (record["docDate"] === openingStockDate) {
        let value = new Date();
        handleRecordChange({
          target: { name: "docDate", value, type, checked },
        });
      }
    } else if (name === "projectType" && value === "FTTH" && record.docLocatorType === "CS") {
      handleRecordChange({
        target: { name: "receiverDocType", value: "CO", type, checked },
      });
    }
  };

  return (
    <RecordAdditionForm
      formMinWidth={"38vw"}
      entityType="material-inward"
      isValidEntityRecord={isValidRecord}
      handleEntitySpecificChange={handleEntitySpecificChange}
    />
  );
}

export default withAuth(MaterialInwardForm);
