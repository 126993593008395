import { Field } from "../types/field";

export const companyTypeValues = [
  { value: "LTD", label: "Public Limited" },
  { value: "PVT", label: "Private Limited" },
  { value: "LLP", label: "LLP" },
  { value: "PCO", label: "Partnership Co." },
  { value: "PRO", label: "Properitorship" },
  { value: "OPC", label: "One Person Company" },
];

export const companyFields: Field[] = [
  {
    name: "companyName",
    label: "Company Name",
    type: "text",
  },
  {
    name: "section1",
    type: "formSection",
    childFields: [
      {
        name: "companyType",
        label: "Company Type",
        type: "picklist",
        allowUpdate: true,
        defaultValue: "LTD",
        values: companyTypeValues,
      },
      {
        name: "companyCode",
        label: "Company Code",
        type: "text",
        autoFilled: true,
        allowNull: true,
      },
    ],
  },
  {
    name: "section1",
    type: "formSection",
    childFields: [
      {
        name: "ownerFlg",
        label: "Store Owner",
        type: "checkbox",
        defaultValue: false,
        disability: {
          type: "OR",
          filters: [
            { type: "IN", field: "contractorFlg", values: [true] },
            { type: "IN", field: "foreignFlg", values: [true] },
          ],
        },
        linkedFields: [
          {
            name: "contractorFlg",
            value: false,
          },
          {
            name: "foreignFlg",
            value: false,
          },
        ],
      },
      {
        name: "contractorFlg",
        label: "Contractor",
        type: "checkbox",
        defaultValue: false,
        disability: {
          type: "OR",
          filters: [
            { type: "IN", field: "ownerFlg", values: [true] },
            { type: "IN", field: "foreignFlg", values: [true] },
          ],
        },
        linkedFields: [
          {
            name: "ownerFlg",
            value: false,
          },
          {
            name: "foreignFlg",
            value: false,
          },
        ],
      },
      {
        name: "foreignFlg",
        label: "Foreign",
        type: "checkbox",
        defaultValue: false,
        disability: {
          type: "OR",
          filters: [
            { type: "IN", field: "ownerFlg", values: [true] },
            { type: "IN", field: "contractorFlg", values: [true] },
          ],
        },
        linkedFields: [
          {
            name: "ownerFlg",
            value: false,
          },
          {
            name: "contractorFlg",
            value: false,
          },
          {
            name: "stationName",
            value: null,
          },
          {
            name: "stateName",
            value: null,
          },
          {
            name: "panNo",
            value: null,
          },
          {
            name: "gstFlg",
            value: false,
          },
        ],
      },
    ],
  },
  {
    name: "address1",
    label: "Address1",
    type: "text",
    defaultValue: "",
    allowUpdate: true,
  },
  {
    name: "address2",
    label: "Address2",
    type: "text",
    defaultValue: "",
    allowUpdate: true,
    allowNull: true,
  },
  {
    name: "section2",
    type: "formSection",
    childFields: [
      {
        name: "stationName",
        label: "Station Name",
        props: {
          width: "14vw",
        },
        type: "lookup",
        allowUpdate: true,
        lookupType: "station",
        lookupFilter: {
          type: "AND",
          filters: [
            {
              type: "EQUALS",
              field: "foreignFlg",
              resolveValue: true,
              valueFieldName: "foreignFlg",
            },
          ],
        },
        dependentFields: [
          {
            lookupFieldName: "id",
            fieldName: "stationCode",
          },
          {
            additionalFieldName: "details",
            lookupFieldName: "stateCode",
            fieldName: "stateCode",
          },
          {
            additionalFieldName: "details",
            lookupFieldName: "stateName",
            fieldName: "stateName",
          },
          {
            additionalFieldName: "details",
            lookupFieldName: "country",
            fieldName: "country",
          },
        ],
      },
      {
        name: "stationCode",
        label: "Station Code",
        props: {
          width: "6vw",
        },
        type: "text",
        autoFilled: true,
      },
    ],
  },
  {
    name: "section2",
    type: "formSection",
    childFields: [
      {
        name: "stateName",
        label: "State Name",
        type: "text",
        autoFilled: true,
        defaultValue: "",
        disability: {
          type: "IN",
          field: "foreignFlg",
          values: [true],
        },
      },
      {
        name: "country",
        label: "Country",
        type: "text",
        autoFilled: true,
      },
    ],
  },
  {
    name: "section2",
    type: "formSection",
    childFields: [
      {
        name: "panNo",
        label: "Pan Number",
        type: "text",
        defaultValue: "",
        disability: {
          type: "IN",
          field: "foreignFlg",
          values: [true],
        },
        props: {
          width: "14vw",
        },
      },
      {
        name: "id",
        label: "Company Id",
        type: "text",
        autoFilled: true,
        allowNull: true,
        props: {
          width: "6vw",
        },
      },
    ],
  },
  {
    name: "section3",
    type: "formSection",
    childFields: [
      {
        name: "supplierFlg",
        label: "For Supplies",
        type: "checkbox",
        defaultValue: false,
        allowUpdate: true,
      },
      {
        name: "serviceFlg",
        label: "For Services",
        type: "checkbox",
        defaultValue: false,
        allowUpdate: true,
      },
    ],
  },
  {
    name: "section4",
    type: "formSection",
    childFields: [
      {
        name: "gstFlg",
        label: "GST Registered",
        type: "checkbox",
        defaultValue: false,
        disability: {
          type: "IN",
          field: "foreignFlg",
          values: [true],
        },
      },
      {
        name: "active",
        label: "Active",
        type: "checkbox",
        allowUpdate: true,
        defaultValue: false,
      },
    ],
  },
];

export const companyColumns = [
  {
    name: "companyCode",
    label: "Code",
    type: "text",
  },
  {
    name: "companyName",
    label: "Company Name",
    type: "text",
  },
  {
    name: "ownerFlg",
    label: "Store Owner",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
  {
    name: "contractorFlg",
    label: "Contractor",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
  {
    name: "stationName",
    label: "Station",
    type: "text",
  },
  {
    name: "stateName",
    label: "State",
    type: "text",
  },
  {
    name: "country",
    label: "Country",
    type: "text",
  },
  {
    name: "foreignFlg",
    label: "Foreign",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
  {
    name: "panNo",
    label: "Pan Number",
    type: "text",
  },
  {
    name: "supplierFlg",
    label: "Supplier",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
  {
    name: "serviceFlg",
    label: "Services",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
  {
    name: "active",
    label: "Active",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
  {
    name: "id",
    label: "Id",
    type: "text",
  },
];

export const companyExcelColumns = [
  {
    name: "companyCode",
    label: "Code",
    type: "text",
  },
  {
    name: "companyName",
    label: "Company Name",
    type: "text",
  },
  {
    name: "companyType",
    label: "Company Type",
    type: "text",
    format: (value) => companyTypeValues.find((item) => item.value === value).label,
  },
  {
    name: "ownerFlg",
    label: "Store Owner",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
  {
    name: "contractorFlg",
    label: "Contractor",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
  {
    name: "address1",
    label: "Address1",
    type: "text",
  },
  {
    name: "address2",
    label: "Address2",
    type: "text",
  },
  {
    name: "stationName",
    label: "Station",
    type: "text",
  },
  {
    name: "stateName",
    label: "State",
    type: "text",
  },
  {
    name: "country",
    label: "Country",
    type: "text",
  },
  {
    name: "foreignFlg",
    label: "Foreign",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
  {
    name: "panNo",
    label: "Pan Number",
    type: "text",
  },
  {
    name: "supplierFlg",
    label: "Supplier",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
  {
    name: "buyerFlg",
    label: "Buyer",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
  {
    name: "serviceFlg",
    label: "For Service",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
  {
    name: "gstFlg",
    label: "GST Registered",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
  {
    name: "active",
    label: "Active",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
  {
    name: "id",
    label: "Company Id",
    type: "text",
  },
];

export const companySearchColumns = [
  {
    name: "companyCode",
    label: "Code",
    type: "text",
  },
  {
    name: "companyName",
    label: "Company Name",
    type: "text",
    props: {
      widthFactor: 2,
    },
  },
  {
    name: "ownerFlg",
    label: "Store Owner",
    type: "picklist",
    defaultValue: "",
    values: [
      { value: true, label: "True" },
      { value: false, label: "False" },
    ],
  },
  {
    name: "contractorFlg",
    label: "Contractor",
    type: "picklist",
    defaultValue: "",
    values: [
      { value: true, label: "True" },
      { value: false, label: "False" },
    ],
  },
  {
    name: "stationName",
    label: "Station",
    type: "text",
  },
  {
    name: "stateName",
    label: "State",
    type: "text",
  },
  {
    name: "country",
    label: "Country",
    type: "text",
  },
  {
    name: "foreignFlg",
    label: "Foreign",
    type: "picklist",
    defaultValue: "",
    values: [
      { value: true, label: "True" },
      { value: false, label: "False" },
    ],
  },
  {
    name: "panNo",
    label: "Pan Number",
    type: "text",
  },
  {
    name: "supplierFlg",
    label: "Supplier",
    type: "picklist",
    defaultValue: "",
    values: [
      { value: true, label: "True" },
      { value: false, label: "False" },
    ],
  },
  {
    name: "serviceFlg",
    label: "Services",
    type: "picklist",
    defaultValue: "",
    values: [
      { value: true, label: "True" },
      { value: false, label: "False" },
    ],
  },
  {
    name: "active",
    label: "Active",
    type: "picklist",
    defaultValue: "",
    values: [
      { value: true, label: "True" },
      { value: false, label: "False" },
    ],
  },
];

export const companyGstFields = [
  {
    name: "companyName",
    label: "Company Name",
    type: "lookup",
    lookupType: "company",
    lookupCategory: "company",
    lookupFilter: {
      type: "AND",
      filters: [
        {
          type: "EQUALS",
          field: "foreignFlg",
          values: false,
        },
        {
          type: "EQUALS",
          field: "gstFlg",
          values: true,
        },
      ],
    },
    dependentFields: [
      {
        lookupFieldName: "id",
        fieldName: "companyCode",
      },
      {
        additionalFieldName: "details",
        lookupFieldName: "panNo",
        fieldName: "panNo",
      },
      {
        additionalFieldName: "details",
        lookupFieldName: "ownerFlg",
        fieldName: "ownerFlg",
      },
      {
        additionalFieldName: "details",
        lookupFieldName: "contractorFlg",
        fieldName: "",
      },
      {
        additionalFieldName: "details",
        lookupFieldName: "gstFlg",
        fieldName: "",
      },
    ],
  },
  {
    name: "companyCode",
    label: "Company Code",
    type: "text",
    autoFilled: true,
  },
  {
    name: "gstNo",
    label: "GST Number",
    type: "text",
  },
  {
    name: "address1",
    label: "Address1",
    type: "text",
    allowUpdate: true,
    defaultValue: "",
  },
  {
    name: "address2",
    label: "Address2",
    type: "text",
    allowUpdate: true,
    allowNull: true,
    defaultValue: "",
  },
  {
    name: "section2",
    type: "formSection",
    childFields: [
      {
        name: "stationName",
        label: "Station Name",
        type: "lookup",
        allowUpdate: true,
        props: {
          width: "14vw",
        },
        lookupType: "station",
        lookupFilter: {
          type: "EXISTS",
          field: "gstCode",
        },
        dependentFields: [
          {
            lookupFieldName: "id",
            fieldName: "stationCode",
          },
          {
            additionalFieldName: "details",
            lookupFieldName: "stateCode",
            fieldName: "stateCode",
          },
          {
            additionalFieldName: "details",
            lookupFieldName: "gstCode",
            fieldName: "gstCode",
          },
          {
            additionalFieldName: "details",
            lookupFieldName: "stateName",
            fieldName: "stateName",
          },
          {
            additionalFieldName: "details",
            lookupFieldName: "country",
            fieldName: "country",
          },
        ],
      },
      {
        name: "stationCode",
        label: "Station Code",
        type: "text",
        props: {
          width: "6vw",
        },
        autoFilled: true,
      },
    ],
  },
  {
    name: "section2",
    type: "formSection",
    childFields: [
      {
        name: "gstCode",
        label: "GST Code",
        props: {
          width: "6vw",
        },
        type: "text",
        autoFilled: true,
        defaultValue: "",
      },
      {
        name: "stateName",
        label: "State Name",
        props: {
          width: "14vw",
        },
        type: "text",
        autoFilled: true,
        defaultValue: "",
      },
    ],
  },
  {
    name: "section2",
    type: "formSection",
    childFields: [
      {
        name: "country",
        label: "Country",
        type: "text",
        autoFilled: true,
        props: {
          width: "14vw",
        },
      },
      {
        name: "id",
        label: "GST Id",
        type: "text",
        autoFilled: true,
        allowNull: true,
        props: {
          width: "6vw",
        },
      },
    ],
  },
  {
    name: "active",
    label: "Active",
    type: "checkbox",
    allowUpdate: true,
    defaultValue: false,
  },
];

export const companyGstColumns = [
  {
    name: "companyCode",
    label: "Code",
    type: "text",
  },
  {
    name: "companyName",
    label: "Company Name",
    type: "text",
  },
  {
    name: "gstNo",
    label: "GST Number",
    type: "text",
  },
  {
    name: "ownerFlg",
    label: "Store Owner",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
  {
    name: "contractorFlg",
    label: "Contractor",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
  {
    name: "address1",
    label: "Address1",
    type: "text",
  },
  {
    name: "stationName",
    label: "Station",
    type: "text",
  },
  {
    name: "stateName",
    label: "State",
    type: "text",
  },
  {
    name: "active",
    label: "Active",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
];

export const companyGstExcelColumns = [
  {
    name: "companyCode",
    label: "Code",
    type: "text",
  },
  {
    name: "companyName",
    label: "Company Name",
    type: "text",
  },
  {
    name: "companyType",
    label: "Company Type",
    type: "text",
    format: (value) => {
      const companyType = companyTypeValues.find((item) => item.value === value);
      return companyType ? companyType.label : "Unknown";
    },
  },
  {
    name: "gstNo",
    label: "GST Number",
    type: "text",
  },
  {
    name: "ownerFlg",
    label: "Store Owner",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
  {
    name: "contractorFlg",
    label: "Contractor",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
  {
    name: "address1",
    label: "Address1",
    type: "text",
  },
  {
    name: "address2",
    label: "Address2",
    type: "text",
  },
  {
    name: "stationName",
    label: "Station",
    type: "text",
  },
  {
    name: "stateName",
    label: "State",
    type: "text",
  },
  {
    name: "gstCode",
    label: "GST Code",
    type: "text",
  },
  {
    name: "active",
    label: "Active",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
  {
    name: "id",
    label: "GST Id",
    type: "text",
  },
];

export const companyGstSearchColumns = [
  {
    name: "companyGst.companyCode",
    label: "Code",
    type: "text",
    props: {
      widthFactor: 0.7,
    },
  },
  {
    name: "companyName",
    label: "Company Name",
    type: "text",
    props: {
      widthFactor: 2,
    },
  },
  {
    name: "gstNo",
    label: "GST Number",
    type: "text",
  },
  {
    name: "companyGst.address1",
    label: "Address1",
    type: "text",
  },
  {
    name: "stationName",
    label: "Station",
    type: "text",
  },
  {
    name: "stateName",
    label: "State",
    type: "text",
  },
  {
    name: "active",
    label: "Active",
    type: "picklist",
    defaultValue: "",
    values: [
      { value: true, label: "True" },
      { value: false, label: "False" },
    ],
    props: {
      widthFactor: 0.7,
    },
  },
];
