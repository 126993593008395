import { userTypesWithLables } from "../constants/globalConstants";
import { Field } from "../types/field";

export const dataAccessTypes = [
  { value: 0, label: "None" },
  { value: 1, label: "Read" },
  { value: 2, label: "Read-Write" },
];

export const sharedLocatorTypeValues = [
  { value: "All", label: "All" },
  { value: "MS", label: "Main Store" },
  { value: "CS", label: "Contractor Store" },
];

export const defaultSearchFields = [
  {
    name: "name",
    label: "Name",
    type: "text",
  },
  {
    name: "userId",
    label: "User Id",
    type: "text",
  },
  {
    name: "email",
    label: "Email",
    type: "text",
  },
  {
    name: "userType",
    label: "User Type",
    type: "picklist",
    values: userTypesWithLables,
  },
];

export const columns = [
  { name: "name", label: "Name", minWidth: 100 },
  { name: "userId", label: "User Id", minWidth: 100 },
  { name: "email", label: "Email", minWidth: 100 },
  { name: "mobile", label: "Mobile", minWidth: 100 },
  {
    name: "userType",
    label: "User Type",
    minWidth: 100,
    format: (value) => {
      const userType = userTypesWithLables.find((userType) => userType.value === value);
      return userType ? userType.label : "Unknown";
    },
  },
  {
    name: "approved",
    label: "Approved",
    minWidth: 100,
    format: (value: string) => value.toString(),
  },
  { name: "createdAt", label: "Creation Date", minWidth: 170 },
];

export const sharedLocatorFields: Field[] = [
  {
    name: "locatorType",
    label: "Locator Type",
    type: "picklist",
    values: sharedLocatorTypeValues,
    defaultValue: "All",
    props: {
      widthFactor: 1.4,
    },
  },
  {
    name: "companyName",
    label: "Company Name",
    type: "lookup",
    lookupType: "company",
    lookupCategory: "locatorAccessCompany",
    allowNull: true,
    props: {
      widthFactor: 1.4,
    },
    dependentFields: [
      {
        lookupFieldName: "id",
        fieldName: "companyCode",
      },
    ],
  },
  {
    name: "companyCode",
    label: "Company Code",
    type: "text",
    defaultValue: "All",
    autoFilled: true,
    props: {
      widthFactor: 0.7,
    },
  },
  {
    name: "contractorName",
    label: "Contractor Name",
    type: "lookup",
    lookupType: "company",
    lookupCategory: "locatorAccessContractor",
    allowNull: true,
    props: {
      widthFactor: 1.4,
    },
    dependentFields: [
      {
        lookupFieldName: "id",
        fieldName: "contractorCode",
      },
    ],
  },
  {
    name: "contractorCode",
    label: "Contractor Code",
    type: "text",
    autoFilled: true,
    defaultValue: "All",
    props: {
      widthFactor: 0.7,
    },
  },
  {
    name: "stateName",
    label: "State Name",
    type: "lookup",
    lookupType: "state",
    lookupCategory: "locatorAccessState",
    allowNull: true,
    props: {
      widthFactor: 1.4,
    },
    dependentFields: [
      {
        lookupFieldName: "id",
        fieldName: "stateCode",
      },
    ],
  },
  {
    name: "stateCode",
    label: "State Code",
    type: "text",
    autoFilled: true,
    defaultValue: "All",
    props: {
      widthFactor: 0.7,
    },
  },
  {
    name: "locatorName",
    label: "Locator Name",
    type: "lookup",
    lookupType: "locator",
    lookupCategory: "locatorAccessLocator",
    lookupParams: [
      {
        key: "companyCode",
        resolveValue: true,
        valueFieldName: "companyCode",
      },
      {
        key: "contractorCode",
        resolveValue: true,
        valueFieldName: "contractorCode",
      },
      {
        key: "stateCode",
        resolveValue: true,
        valueFieldName: "stateCode",
      },
      {
        key: "locatorType",
        resolveValue: true,
        valueFieldName: "locatorType",
      },
    ],
    allowNull: true,
    props: {
      widthFactor: 1.4,
    },
    dependentFields: [
      {
        lookupFieldName: "id",
        fieldName: "locatorCode",
      },
    ],
  },
  {
    name: "locatorCode",
    label: "Locator Code",
    type: "text",
    autoFilled: true,
    defaultValue: "All",
    props: {
      widthFactor: 0.7,
    },
  },
];

export const userFields: Field[] = [
  {
    name: "userId",
    label: "User Id",
    type: "text",
  },
  {
    name: "name",
    label: "Name",
    type: "text",
    allowUpdate: true,
  },
  {
    name: "email",
    label: "Email",
    type: "text",
    allowUpdate: true,
  },
  {
    name: "mobile",
    label: "Mobile",
    type: "text",
    allowUpdate: true,
  },
  {
    name: "userType",
    label: "User Type",
    type: "picklist",
    values: userTypesWithLables,
    allowUpdate: true,
  },
  {
    name: "masterAccessType",
    label: "Master Data Access",
    type: "picklist",
    values: dataAccessTypes,
    allowUpdate: true,
  },
  {
    name: "transactionAccessType",
    label: "Transaction Data Access",
    type: "picklist",
    values: dataAccessTypes,
    allowUpdate: true,
  },
  {
    name: "approved",
    label: "Approved",
    type: "checkbox",
    allowUpdate: true,
  },
  {
    name: "allAccess",
    label: "All Access",
    type: "checkbox",
    allowUpdate: true,
    disability: {
      type: "EXISTS",
      field: "locatorAccessConfig",
    },
  },
  {
    name: "locatorAccessConfig",
    label: "Shared Locators",
    type: "formTable",
    allowUpdate: true,
    allowNull: true,
    tableFields: sharedLocatorFields,
    props: {
      width: "84vw",
    },
    disability: {
      type: "OR",
      filters: [
        {
          type: "IN",
          field: "allAccess",
          values: [true],
        },
      ],
    },
  },
];

export const userColumns = [
  { name: "name", label: "Name", minWidth: 100 },
  { name: "userId", label: "User Id", minWidth: 100 },
  { name: "email", label: "Email", minWidth: 100 },
  { name: "mobile", label: "Mobile", minWidth: 100 },
  {
    name: "userType",
    label: "User Type",
    minWidth: 100,
    format: (value) => {
      const userType = userTypesWithLables.find((userType) => userType.value === value);
      return userType ? userType.label : "Unknown";
    },
  },
  {
    name: "approved",
    label: "Approved",
    minWidth: 100,
    format: (value) => value.toString(),
  },
  { name: "createdAt", label: "Creation Date", minWidth: 170 },
];
