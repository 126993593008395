import { Field } from "../types/field";
import { translatePicklistValue } from "../services/fieldsUtils";
import { formatDate } from "../services/dateUtils";

export const projectTypeValues = [
  { value: "FF", label: "FF Work" },
  { value: "FTTH", label: "FTTH Work" },
];

export const cwoTypeValues = [
  { value: "OVH", label: "Over Head" },
  { value: "UGD", label: "Under Ground" },
  { value: "OTH", label: "Others" },
];

export const childWorkOrderFields: Field[] = [
  {
    name: "projectName",
    label: "Project Name",
    type: "lookup",
    lookupType: "project",
    lookupCategory: "workProjects",
    lookupFilter: {
      type: "AND",
      filters: [
        {
          type: "EQUALS",
          field: "stateCode",
          resolveValue: true,
          valueFieldName: "locatorStateCode",
        },
        {
          type: "IN",
          field: "projectType",
          values: ["FTTH"],
        },
      ],
    },
    dependentFields: [
      {
        lookupFieldName: "id",
        fieldName: "projectCode",
      },
      {
        additionalFieldName: "details",
        lookupFieldName: "stateCode",
        fieldName: "stateCode",
      },
      {
        additionalFieldName: "details",
        lookupFieldName: "stateName",
        fieldName: "stateName",
      },
      {
        additionalFieldName: "details",
        lookupFieldName: "projectType",
        fieldName: "projectType",
      },
    ],
  },
  {
    name: "section1",
    type: "formSection",
    childFields: [
      {
        name: "projectType",
        label: "Project Type",
        type: "picklist",
        defaultValue: "",
        autoFilled: true,
        values: [
          { value: "FF", label: "FF Work" },
          { value: "FTTH", label: "FTTH Work" },
        ],
        props: {
          width: "14vw",
        },
      },
      {
        name: "projectCode",
        label: "Project Code",
        type: "text",
        autoFilled: true,
        props: {
          width: "6vw",
        },
      },
    ],
  },
  {
    name: "section1",
    type: "formSection",
    childFields: [
      {
        name: "cwoType",
        label: "CWO Type",
        type: "picklist",
        allowUpdate: true,
        defaultValue: "",
        values: [
          { value: "OVH", label: "Over Head" },
          { value: "UGD", label: "Under Ground" },
          { value: "OTH", label: "Others" },
        ],
      },
      {
        name: "stateName",
        label: "State Name",
        type: "text",
        autoFilled: true,
        allowNull: true,
      },
    ],
  },
  {
    name: "cwoNo",
    label: "CWO No",
    type: "text",
  },
  {
    name: "section1",
    type: "formSection",
    childFields: [
      {
        name: "cwoDate",
        label: "CWO Date",
        type: "date",
        defaultValue: "",
        constraints: [
          {
            type: "DATE_RANGE",
            minDateScript: {
              globalFields: ["openingStockDate"],
            },
            beforeNow: true,
          },
        ],
      },
      {
        name: "id",
        label: "CWO Id",
        type: "number",
      },
    ],
  },
  {
    name: "section1",
    type: "formSection",
    childFields: [
      {
        name: "workArea",
        label: "Work Area",
        type: "text",
        allowUpdate: true,
        defaultValue: "",
        props: {
          width: "14vw",
        },
      },
      {
        name: "gisCode",
        label: "Gis Code",
        type: "text",
        allowUpdate: true,
        defaultValue: "",
        props: {
          width: "6vw",
        },
      },
    ],
  },
  {
    name: "section1",
    type: "formSection",
    childFields: [
      {
        name: "homePass",
        label: "Home Pass",
        type: "number",
        allowUpdate: true,
        defaultValue: "",
      },
      {
        name: "routeLength",
        label: "Route Length",
        type: "number",
        allowUpdate: true,
        defaultValue: "",
      },
    ],
  },
  {
    name: "section1",
    type: "formSection",
    childFields: [
      {
        name: "contractorName",
        label: "Contractor Name",
        type: "lookup",
        lookupType: "company",
        lookupCategory: "contractorCompany",
        lookupFilter: { type: "AND", filters: [{ type: "EQUALS", field: "contractorFlg", values: [true] }] },
        props: {
          width: "14vw",
        },
        dependentFields: [
          {
            lookupFieldName: "id",
            fieldName: "contractorCode",
          },
        ],
      },
      {
        name: "contractorCode",
        label: "Contractor Code",
        type: "text",
        props: {
          width: "6vw",
        },
        autoFilled: true,
        allowNull: true,
      },
    ],
  },
  {
    name: "section4",
    type: "formSection",
    childFields: [
      {
        name: "active",
        label: "Active",
        type: "checkbox",
        allowUpdate: true,
        defaultValue: false,
      },
      {
        name: "closed",
        label: "Closed",
        type: "checkbox",
        displayOnly: true,
        allowUpdate: false,
        defaultValue: false,
      },
    ],
  },
];

export const childWorkOrderColumns = [
  {
    name: "id",
    label: "CWO Id",
    type: "text",
  },
  {
    name: "cwoDate",
    label: "CWO Date",
    type: "date",
    format: (value) => formatDate(value),
  },
  {
    name: "cwoType",
    label: "CWO Type",
    type: "text",
    format: (value) => translatePicklistValue(value, cwoTypeValues),
  },
  {
    name: "cwoNo",
    label: "CWO No",
    type: "text",
  },
  {
    name: "homePass",
    label: "Home Pass",
    type: "number",
  },
  {
    name: "routeLength",
    label: "Route Length",
    type: "number",
  },
  {
    name: "workArea",
    label: "Work Area",
    type: "text",
  },
  {
    name: "stateName",
    label: "State Name",
    type: "text",
  },
  {
    name: "projectCode",
    label: "Project Code",
    type: "text",
  },
  {
    name: "contractorName",
    label: "Contractor Name",
    type: "text",
  },
  {
    name: "active",
    label: "Active",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
  {
    name: "closed",
    label: "Closed",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
];

export const childWorkOrderExcelColumns = [
  {
    name: "id",
    label: "CWO Id",
    type: "text",
  },
  {
    name: "cwoDate",
    label: "CWO Date",
    type: "date",
    format: (value) => formatDate(value),
  },
  {
    name: "cwoType",
    label: "CWO Type",
    type: "text",
    format: (value) => translatePicklistValue(value, cwoTypeValues),
  },
  {
    name: "cwoNo",
    label: "CWO No",
    type: "text",
  },
  {
    name: "homePass",
    label: "CWO Home Pass",
    type: "number",
  },
  {
    name: "routeLength",
    label: "CWO Route Length",
    type: "number",
  },
  {
    name: "workArea",
    label: "Work Area",
    type: "text",
  },
  {
    name: "gisCode",
    label: "GIS Code",
    type: "text",
  },
  {
    name: "stateName",
    label: "State Name",
    type: "text",
  },
  {
    name: "projectCode",
    label: "Project Code",
    type: "text",
  },
  {
    name: "projectName",
    label: "Project Name",
    type: "text",
  },
  {
    name: "projectType",
    label: "Project Type",
    type: "text",
    format: (value) => translatePicklistValue(value, projectTypeValues),
  },
  {
    name: "contractorCode",
    label: "Contractor Code",
    type: "text",
  },
  {
    name: "contractorName",
    label: "Contractor Name",
    type: "text",
  },
  {
    name: "active",
    label: "Active",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
  {
    name: "closed",
    label: "Closed",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
];

export const childWorkOrderSearchColumns = [
  {
    name: "cwoId",
    label: "CWO Id",
    type: "text",
  },
  {
    name: "cwoType",
    label: "CWO Type",
    type: "picklist",
    defaultValue: "",
    values: [
      { value: "OVH", label: "Over Head" },
      { value: "UGD", label: "Under Ground" },
      { value: "OTH", label: "Others" },
    ],
  },
  {
    name: "cwoNo",
    label: "CWO No",
    type: "text",
  },
  {
    name: "workArea",
    label: "Work Area",
    type: "text",
  },
  {
    name: "stateName",
    label: "State Name",
    type: "text",
  },
  {
    name: "childWorkOrder.projectCode",
    label: "Project Code",
    type: "text",
  },
  {
    name: "companyName",
    label: "Contractor",
    type: "text",
  },
  {
    name: "active",
    label: "Active",
    type: "picklist",
    defaultValue: "",
    values: [
      { value: true, label: "True" },
      { value: false, label: "False" },
    ],
  },
  {
    name: "closed",
    label: "Closed",
    type: "picklist",
    defaultValue: "",
    values: [
      { value: true, label: "True" },
      { value: false, label: "False" },
    ],
  },
];
