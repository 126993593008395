import { Field } from "../types/field";
import { translatePicklistValue } from "../services/fieldsUtils";
import { formatDate } from "../services/dateUtils";
import { projectTypeValues } from "../standardEntities/project";

export const jobTypeValues = [
  { value: "BBW", label: "Back Bone" },
  { value: "LMW", label: "Last Mile" },
  { value: "ICW", label: "Inter City" },
  { value: "FTTB", label: "FTTB" },
  { value: "OTH", label: "Others" },
];

export const cwoTypeValues = [
  { value: "OVH", label: "Over Head" },
  { value: "UGD", label: "Under Ground" },
  { value: "OTH", label: "Others" },
];

export const workClosureFields: Field[] = [
  {
    name: "section1",
    type: "formSection",
    childFields: [
      {
        name: "contractorName",
        label: "Contractor Name",
        type: "lookup",
        lookupType: "company",
        lookupCategory: "workClosureContractor",
        lookupFilter: { type: "AND", filters: [{ type: "EQUALS", field: "contractorFlg", values: [true] }] },
        props: {
          width: "14vw",
        },
        dependentFields: [
          {
            lookupFieldName: "id",
            fieldName: "contractorCode",
          },
        ],
        linkedFields: [
          {
            name: "stateCode",
            value: "",
          },
          {
            name: "cwoId",
            value: "",
          },
          {
            name: "jobId",
            value: "",
          },
          {
            name: "atcNo",
            value: "",
          },
          {
            name: "atcDate",
            value: "",
          },
        ],
        allowNull: false,
      },
      {
        name: "contractorCode",
        label: "Contractor Code",
        type: "text",
        props: {
          width: "6vw",
        },
        autoFilled: true,
        allowNull: false,
      },
    ],
  },
  {
    name: "section1",
    type: "formSection",
    childFields: [
      {
        name: "stateCode",
        label: "State Code",
        type: "text",
        defaultValue: "",
        autoFilled: true,
        allowNull: true,
        props: {
          width: "6vw",
        },
      },
      {
        name: "stateName",
        label: "State Name",
        type: "lookup",
        lookupType: "state",
        lookupCategory: "workClosureStates",
        defaultValue: null,
        setDefaultOnDisable: true,
        allowUpdate: false,
        lookupFilter: {
          type: "EQUALS",
          field: "stateCode",
          resolveValue: true,
          valueFieldName: "stateCode",
        },
        lookupParams: [
          {
            key: "contractorCode",
            resolveValue: true,
            valueFieldName: "contractorCode",
          },
        ],
        dependentFields: [
          {
            lookupFieldName: "id",
            fieldName: "stateCode",
          },
        ],
        props: {
          width: "14vw",
        },
        disability: {
          type: "MISSING",
          field: "contractorCode",
        },
        linkedFields: [
          {
            name: "cwoId",
            value: "",
          },
          {
            name: "jobId",
            value: "",
          },
          {
            name: "atcNo",
            value: "",
          },
          {
            name: "atcDate",
            value: "",
          },
        ],
      },
    ],
  },
  {
    name: "section1",
    type: "formSection",
    childFields: [
      {
        name: "projectType",
        label: "Project Type",
        type: "picklist",
        defaultValue: "FF",
        values: [
          { value: "FF", label: "FF Work" },
          { value: "FTTH", label: "FTTH Work" },
        ],
        linkedFields: [
          {
            name: "cwoId",
            value: "",
          },
          {
            name: "jobId",
            value: "",
          },
          {
            name: "atcNo",
            value: "",
          },
          {
            name: "atcDate",
            value: "",
          },
        ],
      },
      {
        name: "jobId",
        label: "Job Id",
        type: "lookup",
        lookupType: "job",
        allowUpdate: false,
        defaultValue: "",
        lookupFilter: {
          type: "AND",
          filters: [
            {
              type: "IN",
              field: "stateCode",
              resolveValue: true,
              valueFieldName: "stateCode",
            },
            {
              type: "IN",
              field: "contractorCode",
              resolveValue: true,
              valueFieldName: "contractorCode",
            },
          ],
        },
        dependentFields: [
          {
            additionalFieldName: "details",
            lookupFieldName: "siteId",
            fieldName: "siteId",
          },
          {
            additionalFieldName: "details",
            lookupFieldName: "projectCode",
            fieldName: "projectCode",
          },
          {
            additionalFieldName: "details",
            lookupFieldName: "projectName",
            fieldName: "projectName",
          },
          {
            additionalFieldName: "details",
            lookupFieldName: "jobType",
            fieldName: "jobType",
          },
          {
            additionalFieldName: "details",
            lookupFieldName: "poNo",
            fieldName: "poNo",
          },
          {
            additionalFieldName: "details",
            lookupFieldName: "todayDate",
            fieldName: "docDate",
          },
        ],
        props: {
          width: "10vw",
        },
        visibility: {
          type: "IN",
          field: "projectType",
          values: ["FF"],
        },
        disability: {
          type: "OR",
          filters: [
            { type: "MISSING", field: "contractorCode" },
            { type: "MISSING", field: "stateCode" },
          ],
        },
      },
      {
        name: "cwoId",
        label: "CWO Id",
        type: "lookup",
        lookupType: "child-work-order",
        lookupCategory: "inwardChildWorkOrder",
        allowUpdate: false,
        defaultValue: "",
        lookupFilter: {
          type: "AND",
          filters: [
            {
              type: "IN",
              field: "stateCode",
              resolveValue: true,
              valueFieldName: "stateCode",
            },
            {
              type: "IN",
              field: "contractorCode",
              resolveValue: true,
              valueFieldName: "contractorCode",
            },
          ],
        },
        dependentFields: [
          {
            lookupFieldName: "id",
            fieldName: "cwoNo",
          },
          {
            additionalFieldName: "details",
            lookupFieldName: "projectCode",
            fieldName: "projectCode",
          },
          {
            additionalFieldName: "details",
            lookupFieldName: "projectName",
            fieldName: "projectName",
          },
          {
            additionalFieldName: "details",
            lookupFieldName: "cwoDate",
            fieldName: "cwoDate",
          },
          {
            additionalFieldName: "details",
            lookupFieldName: "cwoType",
            fieldName: "cwoType",
          },
          {
            additionalFieldName: "details",
            lookupFieldName: "todayDate",
            fieldName: "docDate",
          },
        ],
        props: {
          width: "10vw",
        },
        visibility: {
          type: "NIN",
          field: "projectType",
          values: ["FF"],
        },
        disability: {
          type: "OR",
          filters: [
            { type: "MISSING", field: "contractorCode" },
            { type: "MISSING", field: "stateCode" },
          ],
        },
      },
    ],
  },
  {
    name: "section1",
    type: "formSection",
    childFields: [
      {
        name: "projectCode",
        label: "Project Code",
        type: "text",
        autoFilled: true,
        props: {
          width: "6vw",
        },
      },
      {
        name: "projectName",
        label: "Project Name",
        type: "text",
        autoFilled: true,
        allowNull: true,
        props: {
          width: "14vw",
        },
      },
    ],
  },
  {
    name: "cwoNo",
    label: "CWO No",
    type: "text",
    defaultValue: "",
    autoFilled: true,
    disability: {
      type: "NIN",
      field: "projectType",
      values: ["FTTH"],
    },
    visibility: {
      type: "IN",
      field: "projectType",
      values: ["FTTH"],
    },
  },
  {
    name: "section1",
    type: "formSection",
    visibility: {
      type: "IN",
      field: "projectType",
      values: ["FTTH"],
    },
    childFields: [
      {
        name: "cwoDate",
        label: "CWO Date",
        type: "date",
        autoFilled: true,
        disability: {
          type: "NIN",
          field: "projectType",
          values: ["FTTH"],
        },
      },
      {
        name: "cwoType",
        label: "CWO Type",
        allowNull: true,
        type: "picklist",
        defaultValue: "",
        autoFilled: true,
        values: [
          { value: "OVH", label: "Over Head" },
          { value: "UGD", label: "Under Ground" },
          { value: "OTH", label: "Others" },
        ],
        disability: {
          type: "NIN",
          field: "projectType",
          values: ["FTTH"],
        },
      },
    ],
  },
  {
    name: "siteId",
    label: "Job Site Id",
    type: "text",
    autoFilled: true,
    defaultValue: "",
    disability: {
      type: "NIN",
      field: "projectType",
      values: ["FF"],
    },
    visibility: {
      type: "IN",
      field: "projectType",
      values: ["FF"],
    },
  },
  {
    name: "section1",
    type: "formSection",
    visibility: {
      type: "IN",
      field: "projectType",
      values: ["FF"],
    },
    childFields: [
      {
        name: "jobType",
        label: "Job Type",
        autoFilled: true,
        defaultValue: "",
        allowNull: true,
        type: "picklist",
        values: [
          { value: "BBW", label: "Back Bone" },
          { value: "LMW", label: "Last Mile" },
          { value: "ICW", label: "Inter City" },
          { value: "FTTB", label: "FTTB" },
          { value: "OTH", label: "Others" },
        ],
        disability: {
          type: "NIN",
          field: "projectType",
          values: ["FF"],
        },
      },
      {
        name: "poNo",
        label: "PO Number",
        type: "text",
        autoFilled: true,
        defaultValue: "",
        allowNull: true,
        disability: {
          type: "NIN",
          field: "projectType",
          values: ["FF"],
        },
      },
    ],
  },
  {
    name: "section1",
    type: "formSection",
    visibility: {
      type: "IN",
      field: "projectType",
      values: ["FF"],
    },
    childFields: [
      {
        name: "atcNo",
        label: "ATC No",
        type: "number",
        disability: {
          type: "NIN",
          field: "projectType",
          values: ["FF"],
        },
      },
      {
        name: "atcDate",
        label: "ATC Date",
        type: "date",
        constraints: [{ type: "DATE_RANGE", beforeNow: true }],
        disability: {
          type: "NIN",
          field: "projectType",
          values: ["FF"],
        },
      },
    ],
  },
  {
    name: "section1",
    type: "formSection",
    childFields: [
      {
        name: "id",
        label: "Closure No",
        displayOnly: true,
        allowNull: true,
        type: "text",
      },
      {
        name: "docDate",
        label: "Closure Date",
        type: "date",
        allowNull: true,
        displayOnly: true,
      },
    ],
  },
  {
    name: "sections",
    type: "formSection",
    childFields: [
      {
        name: "cancelled",
        label: "Cancelled",
        type: "checkbox",
        defaultValue: false,
        allowUpdate: true,
        disabledForCreation: true,
        // disability: {
        //   type: "OR",
        //   filters: [
        //     {
        //       type: "MISSING",
        //       field: "id",
        //     },
        //   ],
        // },
      },
    ],
  },
];

export const workClosureColumns = [
  {
    name: "id",
    label: "Closure No",
    type: "text",
  },
  {
    name: "docDate",
    label: "Closure Date",
    type: "date",
    format: (value) => formatDate(value),
  },
  {
    name: "projectType",
    label: "Project Type",
    type: "text",
    format: (value) => translatePicklistValue(value, projectTypeValues),
  },
  {
    name: "projectCode",
    label: "Project",
    type: "text",
  },
  {
    name: "projectName",
    label: "Project Name",
    type: "text",
  },
  {
    name: "contractorName",
    label: "Contractor Name",
    type: "text",
  },
  {
    name: "stateName",
    label: "State Name",
    type: "text",
  },
  {
    name: "workId",
    label: "CWO/Job Id",
    type: "text",
  },
  {
    name: "workType",
    label: "CWO/Job Type",
    type: "text",
    format: (value) => translatePicklistValue(value, [...jobTypeValues, ...cwoTypeValues]),
  },
  {
    name: "atcNo",
    label: "ATC No",
    type: "text",
  },
  {
    name: "atcDate",
    label: "ATC Date",
    type: "date",
    format: (value) => formatDate(value),
  },
  {
    name: "cancelled",
    label: "Cancelled",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
];

export const workClosureExcelColumns = [
  {
    name: "id",
    label: "Closure No",
    type: "text",
  },
  {
    name: "docDate",
    label: "Closure Date",
    type: "date",
    format: (value) => formatDate(value),
  },
  {
    name: "projectType",
    label: "Project Type",
    type: "text",
    format: (value) => translatePicklistValue(value, projectTypeValues),
  },
  {
    name: "projectCode",
    label: "Project",
    type: "text",
  },
  {
    name: "projectName",
    label: "Project Name",
    type: "text",
  },
  {
    name: "contractorCode",
    label: "Contractor Code",
    type: "text",
  },
  {
    name: "contractorName",
    label: "Contractor Name",
    type: "text",
  },
  {
    name: "stateName",
    label: "State Name",
    type: "text",
  },
  {
    name: "workId",
    label: "CWO/Job Id",
    type: "text",
  },
  {
    name: "workType",
    label: "CWO/Job Type",
    type: "text",
    format: (value) => translatePicklistValue(value, [...jobTypeValues, ...cwoTypeValues]),
  },
  {
    name: "cwoId",
    label: "CWO Id",
    type: "text",
  },
  {
    name: "cwoDate",
    label: "CWO Date",
    type: "date",
    format: (value) => formatDate(value),
  },
  {
    name: "cwoType",
    label: "CWO Type",
    type: "text",
    format: (value) => translatePicklistValue(value, cwoTypeValues),
  },
  {
    name: "jobId",
    label: "Job Id",
    type: "text",
  },
  {
    name: "jobType",
    label: "Job Type",
    type: "text",
    format: (value) => translatePicklistValue(value, jobTypeValues),
  },
  {
    name: "poNo",
    label: "PO No",
    type: "text",
  },
  {
    name: "siteId",
    label: "Job Site Id",
    type: "text",
  },
  {
    name: "atcNo",
    label: "ATC No",
    type: "text",
  },
  {
    name: "atcDate",
    label: "ATC Date",
    type: "date",
    format: (value) => formatDate(value),
  },
  {
    name: "cancelled",
    label: "Cancelled",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
];

export const workClosureSearchColumns = [
  {
    name: "closureId",
    label: "Closure No",
    type: "text",
  },
  {
    name: "projectType",
    label: "Project Type",
    type: "picklist",
    defaultValue: "",
    values: [
      { value: "FF", label: "FF Work" },
      { value: "FTTH", label: "FTTH Work" },
    ],
  },
  {
    name: "workClosure.projectCode",
    label: "Project",
    type: "text",
  },
  {
    name: "projectName",
    label: "Project Name",
    type: "text",
  },
  {
    name: "companyName",
    label: "Contractor Name",
    type: "text",
  },
  {
    name: "stateName",
    label: "State Name",
    type: "text",
  },
  {
    name: "workId",
    label: "CWO/Job Id",
    type: "text",
  },
  {
    name: "workType",
    label: "CWO/Job Type",
    type: "picklist",
    defaultValue: "",
    values: [
      { value: "BBW", label: "Back Bone" },
      { value: "LMW", label: "Last Mile" },
      { value: "ICW", label: "Inter City" },
      { value: "FTTB", label: "FTTB" },
      { value: "OVH", label: "Over Head" },
      { value: "UGD", label: "Under Ground" },
      { value: "OTH", label: "Others" },
    ],
  },
  {
    name: "atcNo",
    label: "ATC No",
    type: "text",
  },
  {
    name: "cancelled",
    label: "Cancelled",
    type: "picklist",
    values: [
      { value: true, label: "True" },
      { value: false, label: "False" },
    ],
  },
];
