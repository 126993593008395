import withAuth from "../../hocs/withAuth";
import GenerateReportForm from "./GenerateReportForm";
import { stockAgeingAvailableColumns, stockAgeingPhysicalColumns } from "../../standardEntities/stockView";
import { translatePicklistValue } from "../../services/fieldsUtils";
import { stockAgeingFormFields } from "./formFields/stockAgeingFormFields";
import { stockTypeValues } from "./reportUtils";

const getExcelColumns = (searchRequest) => {
  return reportCategoryToExcelColumns[searchRequest.stockType];
};

const reportCategoryToExcelColumns = {
  A: stockAgeingAvailableColumns,
  P: stockAgeingPhysicalColumns,
};

const StockAgeingForm = ({ formMinWidth = "38vw" }) => {
  return (
    <GenerateReportForm
      formMinWidth={formMinWidth}
      fields={stockAgeingFormFields}
      getExcelColumns={getExcelColumns}
      pageTitle={"Stock Ageing Report"}
      getReportName={(searchRequest) => `Stock Ageing Report (${translatePicklistValue(searchRequest.stockType, stockTypeValues)})`}
      reportType={"stock-ageing"}
    />
  );
};

export default withAuth(StockAgeingForm);
