import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import _ from "lodash";
import { isEmptyObj } from "./utils";
import { formatValueForDisplay } from "./fieldsUtils";

export const generateExcelReport = async ({
  fetchReportData,
  fileName,
  searchRequest,
  fieldNameToFieldMap,
}) => {
  let fetchResponse = await fetchReportData();
  if (!fetchResponse || fetchResponse == null) {
    return;
  }
  let worksheet = XLSX.utils.json_to_sheet(fetchResponse);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Data");
  addSearchRequestSheet(searchRequest, fieldNameToFieldMap, workbook, fileName);
  const excelBuffer = XLSX.write(workbook, {
    bookType: "xlsx",
    type: "array",
  });
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const file = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(file, fileName + ".xlsx");
};

export const createExcelReportFromRecords = (records, excelColumns) => {
  if (_.isEmpty(records)) {
    return [];
  }
  if (_.isEmpty(excelColumns)) {
    return records;
  }
  let adaptedRecords = records.map((record) => {
    let adaptedRecord = {};
    excelColumns.forEach((column) => {
      let columnValue = record[column.name];
      if (column.format) {
        columnValue = column.format(columnValue);
      }
      adaptedRecord[column.label] = columnValue;
    });
    return adaptedRecord;
  });
  return adaptedRecords;
};
const addSearchRequestSheet = (
  searchRequest,
  fieldNameToFieldMap,
  workbook,
  reportName,
) => {
  if (!isEmptyObj(searchRequest)) {
    let searchRequestRows = _.map(searchRequest, (Value, Key) => {
      let field = fieldNameToFieldMap[Key];
      let { label, type } = field;
      return {
        Key: `${label} (${Key})`,
        Value: formatValueForDisplay(Value, type, field),
      };
    });
    searchRequestRows.push({
      Key: "Report Type",
      Value: reportName,
    });
    const searchRequestWorksheet = XLSX.utils.json_to_sheet(searchRequestRows);
    XLSX.utils.book_append_sheet(
      workbook,
      searchRequestWorksheet,
      "Search Request",
    );
  }
};
