import React, { useState, useEffect, useMemo } from "react";
import { getColumnsForRecordType } from "../../services/standardEntityFieldService";
import { debounce } from "@mui/material/utils";
import _ from "lodash";
import Stack from "@mui/material/Stack";
import { apiCall } from "../../services/api";
import moment from "moment-timezone";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import RecordTable from "../recordStandardForms/RecordTable";
import SearchIcon from "@mui/icons-material/Search";
import { timeZone, dateFormat } from "../../constants/globalConstants";
import RefreshIcon from "@mui/icons-material/Refresh";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import withAuth from "../../hocs/withAuth";
import Divider from "@mui/material/Divider";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import InputAdornment from "@mui/material/InputAdornment";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ClearIcon from "@mui/icons-material/Clear";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  getRecordsSearchRequestFilters,
  getRecordLookupParams,
} from "../../services/utils";

let recordIdToRecordsMap = {};
const today = new Date(); // Current date and time
const sevenDaysAgo = new Date();
sevenDaysAgo.setDate(today.getDate() - 7);

function LookupTable({
  columns = [],
  value = null,
  entityType,
  pageTitle,
  recordType,
  field = {},
  record = {},
  cancelled = false,
  parentGlobalConstants = {},
  disabled = false,
  startDate = sevenDaysAgo,
  endDate = new Date(),
  size = "medium",
  handleSelection = () => {},
}) {
  let {
    lookupType,
    lookupCategory,
    lookupFieldName,
    lookupFilter,
    dependentFields = [],
    lookupParams,
  } = field;

  recordType = entityType ? entityType : recordType;
  console.log("lookupParams", lookupParams);
  console.log("lookupParams field", field);

  if (_.isEmpty(columns)) {
    columns = getColumnsForRecordType(recordType);
  }
  let globalConstants = { ...parentGlobalConstants };

  const [recordTotalCount, setRecordTotalCount] = useState(0);
  const [error, setError] = useState(null);
  const [filteredRecords, setFilteredRecords] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [pageNo, setPageNo] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [query, setQuery] = useState("");
  const [open, setOpen] = useState(false);
  const [fieldValue, setFieldValue] = useState(value);

  useEffect(() => {
    if (value === undefined || value === "" || value == null) {
      changeFieldAndDependentValues(null, value);
    } else {
      setFieldValue(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleClickOpen = () => {
    handleClearInput();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangePage = (event, newPage) => {
    console.log("page ", newPage);
    console.log("rowsPerPage ", rowsPerPage);
    setPageNo(newPage);
    fetchRecordsForFilters({ page: { page: newPage, size: rowsPerPage } });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPageNo(0);
    fetchRecordsForFilters({ page: { page: 0, size: +event.target.value } });
  };

  const fetch = useMemo(
    () =>
      debounce((request, callback) => {
        console.log("request is", request);
        apiCall("post", `/api/entity/${lookupType}/lookup`, request)
          .then((response) => {
            console.log(" respons is", response);

            if (response == null) {
              console.log("Null response for records fetch");
              return;
            }
            callback(response);
          })
          .catch((err) => {
            console.log("Error fetching records", err);
          })
          .finally(() => {});
      }, 400),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [lookupType, parentGlobalConstants, fieldValue],
  );

  const handleClearInput = () => {
    console.log("comes here to handleClearInput");
    setError(null);
    setSelectedRecord(null);
    setRecordTotalCount(0);
    setPageNo(0);
    setQuery("");
    fetchRecordsForFilters({
      page: { page: 0, size: rowsPerPage },
      searchQuery: "",
    });
  };

  const handleClearValue = () => {
    setSelectedRecord(null);
    handleSelection({ target: { name: field.name, value: "" } });
    changeFieldAndDependentValues(null, "");
  };

  const handleQueryChange = (event) => {
    let newQuery = event.target.value;
    setQuery(newQuery);
    fetchRecordsForFilters({
      page: { page: 0, size: rowsPerPage },
      searchQuery: newQuery,
    });
  };

  const handleCrossEvent = () => {
    setSelectedRecord(null);
    fetchRecordsForFilters();
  };

  const handleRecordSelection = async (recordId) => {
    console.log("[table debug] comes here for record id", recordId);
    let selectedRecord = recordIdToRecordsMap[recordId];
    console.log("table debug selectedRecord is", selectedRecord);
    setOpen(false);
    handleSelection({ target: { name: field.name, value: recordId } });
    changeFieldAndDependentValues(selectedRecord, recordId);
  };

  function changeFieldAndDependentValues(newValue, fieldValue) {
    dependentFields.forEach((field) => {
      let value = "";
      if (newValue && field.lookupFieldName) {
        if (
          field.additionalFieldName &&
          newValue.additional &&
          newValue.additional[field.additionalFieldName] &&
          !(
            newValue.additional[field.additionalFieldName][
              field.lookupFieldName
            ] == null
          )
        ) {
          value =
            newValue.additional[field.additionalFieldName][
              field.lookupFieldName
            ];
        } else if (newValue[field.lookupFieldName]) {
          value = newValue[field.lookupFieldName];
        }
      }
      console.log("new Value field is", field);
      console.log("new Value is", value);
      handleSelection({ target: { name: field.fieldName, value } });
    });
    setFieldValue(fieldValue);
  }

  function fetchRecordsForFilters({
    page = { page: pageNo, size: rowsPerPage },
    searchQuery = query,
  }) {
    console.log("comes here to fetch");
    fetch(
      {
        page,
        query: searchQuery,
        returnTotalCount: true,
        filter: getRecordsSearchRequestFilters(
          record,
          lookupFilter,
          globalConstants,
        ),
        lookupCategory,
        lookupFieldName,
        lookupParams: getRecordLookupParams(
          record,
          lookupParams,
          globalConstants,
        ),
      },
      (response) => {
        console.log("response is", response);

        if (response == null) {
          console.log("Null response for records fetch");
          return;
        }
        setFilteredRecords(response.results);
        setRecordTotalCount(response.totalCount);
        addRecordsToMap(response.results);
      },
    );
  }

  function addRecordsToMap(newRecords) {
    if (!_.isEmpty(newRecords)) {
      recordIdToRecordsMap = {};
      newRecords.forEach((record) => {
        recordIdToRecordsMap[record.id] = record;
      });
    }
  }

  console.log("selectedRecord", selectedRecord);
  return (
    <div>
      {" "}
      <FormControl
        sx={{
          textDecoration: cancelled ? "line-through" : "none",
          color: cancelled ? "red" : "inherit",
        }}
        variant="outlined"
      >
        <OutlinedInput
          id="outlined-adornment-password"
          size={size}
          type={"text"}
          disabled={disabled}
          value={fieldValue}
          endAdornment={
            <InputAdornment position="end">
              {fieldValue !== undefined &&
              fieldValue != null &&
              !disabled &&
              fieldValue !== "" ? (
                <IconButton
                  key="clear"
                  aria-label="toggle  clear"
                  onClick={handleClearValue}
                  edge="end"
                >
                  <ClearIcon />
                </IconButton>
              ) : (
                <IconButton
                  disabled={disabled}
                  key="open"
                  aria-label="toggle  open"
                  onClick={handleClickOpen}
                  edge="end"
                >
                  {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                </IconButton>
              )}
            </InputAdornment>
          }
          label="Password"
        />
      </FormControl>
      <Dialog
        // fullWidth={true}
        open={open}
        onClose={handleClose}
        maxWidth={"md"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{pageTitle}</DialogTitle>
        <DialogContent dividers>
          <DialogContent>
            <Stack direction="row">
              <Box
                sx={{
                  marginTop: 1,
                  width: "64vw",
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                <Stack direction="column" alignItems="centertop" spacing={1}>
                  <Box sx={{ marginLeft: 0, width: "64vw" }}>
                    <form>
                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          width: "64vw",
                          flexDirection: "column",
                          justifyContent: "left",
                        }}
                      >
                        <Stack
                          sx={{ mb: 1, alignItems: "center" }}
                          direction="row"
                          alignItems="center"
                          spacing={10}
                        >
                          <Stack
                            sx={{ mb: 1, ml: 1 }}
                            direction="row"
                            alignItems="center"
                            spacing={0}
                          >
                            <div className="form-group" key="query">
                              <TextField
                                id="query"
                                name="query"
                                label="Search"
                                size="small"
                                onChange={handleQueryChange}
                                variant="outlined"
                                type="text"
                                sx={{
                                  mb: 2,
                                  mt: 2,
                                  width: "25vw",
                                  height: "2ch",
                                  fontSize: "1rem",
                                }}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <SearchIcon />
                                    </InputAdornment>
                                  ),
                                }}
                                value={query}
                              />
                            </div>
                            <IconButton
                              aria-label="reset"
                              color="success"
                              fontSize="medium"
                              onClick={handleClearInput}
                            >
                              <RefreshIcon />
                            </IconButton>
                          </Stack>
                          <Stack
                            sx={{ mb: 1, alignItems: "center" }}
                            direction="row"
                            alignItems="center"
                            spacing={1}
                          ></Stack>
                        </Stack>
                      </Box>
                      <Divider sx={{ mb: 3, width: "95%" }} />
                      {/* </Paper> */}
                    </form>
                  </Box>
                </Stack>
              </Box>
            </Stack>
            <Box sx={{ marginTop: 0 }}>
              {error && (
                <div
                  style={{
                    width: "80rem",
                    justifyContent: "center",
                    marginTop: "1rem",
                  }}
                  className="alert alert-danger"
                >
                  {error}
                </div>
              )}
              <Stack direction="row" alignItems="centertop">
                <RecordTable
                  key={field.name}
                  records={filteredRecords}
                  handleRecordSelection={handleRecordSelection}
                  recordsTotalCount={recordTotalCount}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  page={pageNo}
                  rowsPerPage={rowsPerPage}
                  selectedRecord={selectedRecord}
                  columns={columns}
                  tableContainerHeight={"70vh"}
                />
                {selectedRecord && (
                  <Box
                    sx={{
                      width: "50vw",
                      overflow: "hidden", // Hide the content if width is 0
                      transition: "width 0.3s ease-in-out", // Add a transition for a smooth effect
                      marginLeft: 4,
                    }}
                  >
                    <div
                      style={{ width: "80%", marginLeft: "7%" }}
                      className="alert alert-success"
                    >
                      <p>
                        <IconButton
                          aria-label="delete"
                          size="small"
                          name="remove"
                          onClick={handleCrossEvent}
                        >
                          <CancelRoundedIcon fontSize="inherit" name="close" />
                        </IconButton>
                        Selected User: <br />
                        <br />
                        Id: {selectedRecord.id}
                        <br />
                        Name: {selectedRecord.name} <br />
                        Creation Date :{" "}
                        {moment(selectedRecord.createdAt)
                          .tz(timeZone)
                          .format(dateFormat)}
                        <br />
                      </p>
                      <IconButton
                        aria-label="prev"
                        fontSize="medium"
                        onClick={handleClearInput}
                      >
                        <KeyboardArrowLeftIcon />
                      </IconButton>
                      <IconButton
                        aria-label="next"
                        fontSize="medium"
                        onClick={handleClearInput}
                      >
                        <KeyboardArrowRightIcon />
                      </IconButton>
                    </div>
                    <Stack
                      sx={{ ml: 5 }}
                      direction="row"
                      alignItems="center"
                      spacing={1}
                    ></Stack>
                  </Box>
                )}
              </Stack>
            </Box>
          </DialogContent>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="error" onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default withAuth(LookupTable);
