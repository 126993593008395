import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Alert from "@mui/material/Alert";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { apiCall } from "../../services/api";
import store from "../../store";
import { addError } from "../../store/actions/errors";
import { isValidateUser } from "../../services/userUtils";
import { isEmptyObj, toUpperCase } from "../../services/utils";

const VALID_EMAIL_ADDRESS_REGEX = /^[A-Z0-9!#$%&'*+/=?^_`.{|}~-]+@[A-Z0-9.-]+\.[A-Z]{2,63}$/i;

// Example usage:
const email = "example@example.com";
if (VALID_EMAIL_ADDRESS_REGEX.test(email)) {
  console.log("Valid email address");
} else {
  console.log("Invalid email address");
}

const AuthForm = (props) => {
  const [user, setUser] = useState({
    userId: "",
    name: "",
    password: "",
    email: "",
    mobile: null,
    setupTFA: false,
    tfaAuthType: "EMAIL",
  });
  const [formErrors, setFormErrors] = useState({});
  const [createdUser, setCreatedUser] = useState(null);
  const [userPin, setUserPin] = useState("");
  const [verifyTFA, setVerifyTFA] = useState("");
  const history = useHistory();

  const { name, userId, password, email, mobile, setupTFA } = user;
  const { signUp, heading, buttonText, errors, onAuth, removeError } = props;

  useEffect(() => {
    return () => {
      removeError();
    };
  }, [removeError]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const authType = signUp ? "signup" : "signin";
    if (signUp) {
      if (!isValidateUser({ user, setFormErrors })) {
        console.log("Invalid Form Input Params");
        return;
      }
      console.log("user is", user);
      saveNewUser(user);
    } else {
      try {
        let result = await onAuth(authType, user);
    
        if (result.validExistingSession) {
          history.push("/confirmLogin");
        } else if (result.verifyTFA) {
          history.push(`/verifyTFA/${result.userId}`);
        } else {
          history.push("/");
        }
      } catch {
        return;
      }
    }
  };

  function saveNewUser(user) {
    apiCall("post", "/api/auth/signup/", user)
      .then((response) => {
        console.log(response);
        if (response.setupTFA) {
          setVerifyTFA(true);
        }
        setCreatedUser(response);
        removeError();
      })
      .catch((err) => {
        console.log("error inside saving new user", err);
        store.dispatch(addError(err.message || err));
      });
  }

  const handlePinVerify = (e) => {
    apiCall("post", "/api/auth/verifyNewTFA", { userId: user.userId, userPin })
      .then((response) => {
        console.log(response);
        setVerifyTFA(false);
        removeError();
      })
      .catch((err) => {
        setFormErrors((formErrors) => {
          return { ...formErrors, userPin: err.message };
        });
      });
  };

  const handlePinChange = (e) => {
    removeError();
    setUserPin(e.target.value);
  };

  const handleChange = (e) => {
    removeError();
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handleCheckboxChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.checked });
  };

  return (
    <div>
      <Box component="span" sx={{ p: 10, m: 5 }}>
        <div className="row justify-content-md-center text-center">
          <div className="col-md-6">
            <form onSubmit={handleSubmit}>
              <Stack direction="column" alignItems="center" spacing={4}>
                <h3>{heading}</h3>
                <Paper sx={{ m: 5, backgroundColor: "#F7F6FF" }} elevation={10}>
                  <Stack sx={{ m: 5, mt: 5 }} direction="column" alignItems="center" spacing={2}>
                    <TextField
                      id="userId"
                      name="userId"
                      label="User Id"
                      error={formErrors.userId}
                      onInput={toUpperCase}
                      onChange={handleChange}
                      type="text"
                      helperText={formErrors.userId ? formErrors.userId : null}
                      required
                      sx={{ m: 2, width: "30ch" }}
                      value={userId}
                      disabled={verifyTFA}
                    />
                    <TextField
                      id="password"
                      name="password"
                      label="Password"
                      onChange={handleChange}
                      type="password"
                      error={formErrors.password}
                      helperText={formErrors.password ? formErrors.password : null}
                      required
                      sx={{ m: 2, width: "30ch" }}
                      value={password}
                      disabled={verifyTFA}
                    />
                    {signUp && (
                      <>
                        <TextField
                          id="name"
                          name="name"
                          label="Full Name"
                          onChange={handleChange}
                          onInput={toUpperCase}
                          type="text"
                          error={formErrors.name}
                          helperText={formErrors.name ? formErrors.name : null}
                          required
                          sx={{ m: 2, width: "30ch" }}
                          value={name}
                          disabled={verifyTFA}
                        />
                        <TextField
                          id="email"
                          name="email"
                          label="Email Id"
                          onChange={handleChange}
                          type="text"
                          error={formErrors.email}
                          helperText={formErrors.email ? formErrors.email : null}
                          required
                          sx={{ m: 2, width: "30ch" }}
                          value={email}
                          disabled={verifyTFA}
                        />
                        <TextField
                          id="mobile"
                          name="mobile"
                          label="Mobile"
                          onChange={handleChange}
                          type="number"
                          error={formErrors.mobile}
                          helperText={formErrors.mobile ? formErrors.mobile : null}
                          required
                          sx={{ m: 2, width: "30ch" }}
                          value={mobile}
                          disabled={verifyTFA}
                        />
                        {/* <FormControlLabel
                          control={<Checkbox />}
                          label="Turn On Two Factor Authentication"
                          id="setupTFA"
                          name="setupTFA"
                          onChange={handleCheckboxChange}
                          value={setupTFA}
                          disabled={verifyTFA}
                        /> */}
                        {verifyTFA && (
                          <>
                            <TextField
                              id="userPin"
                              name="userPin"
                              label="Pin"
                              onChange={handlePinChange}
                              error={formErrors.userPin}
                              helperText={formErrors.userPin ? formErrors.userPin : null}
                              type="text"
                              required
                              sx={{ m: 2, width: "30ch" }}
                              value={userPin}
                            />
                            <Button disabled={isEmptyObj(userPin)} variant="contained" onClick={handlePinVerify}>
                              Verify
                            </Button>
                          </>
                        )}
                      </>
                    )}
                  </Stack>
                </Paper>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Button disabled={createdUser} variant="contained" color="error" component={Link} to={`/`}>
                    Cancel
                  </Button>
                  <Button disabled={createdUser} variant="contained" type="submit">
                    {buttonText}
                  </Button>
                </Stack>
                {errors.message && <div className="alert alert-danger">{errors.message}</div>}
                {!verifyTFA && createdUser && !createdUser.approved && (
                  <Alert
                    onClose={() => {
                      history.push("/");
                    }}
                  >
                    User created successfully for {createdUser.name} with userId: {createdUser.userId}. <br />
                    You may proceed to login after your user is approved by the system admin.
                  </Alert>
                )}
                {!verifyTFA && createdUser && createdUser.approved && (
                  <Alert
                    onClose={() => {
                      history.push("/");
                    }}
                  >
                    User created successfully for {createdUser.name} with userId: {createdUser.userId}. <br />
                    You may proceed to login as your user is pre-approved.
                  </Alert>
                )}
              </Stack>
            </form>
          </div>
        </div>
      </Box>
    </div>
  );
};

AuthForm.propTypes = {
  buttonText: PropTypes.string,
  errors: PropTypes.object,
  heading: PropTypes.string,
  history: PropTypes.object,
  onAuth: PropTypes.func,
  signUp: PropTypes.bool,
  removeError: PropTypes.func,
};

export default AuthForm;
