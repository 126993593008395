import { useState, useEffect } from "react";
import RecordViewForm from "../recordStandardForms/RecordViewForm";
import withAuth from "../../hocs/withAuth";
import RecordFullViewForm from "../recordStandardForms/RecordFullViewForm";
import { useLocation } from "react-router-dom";
import { apiCall } from "../../services/api";
import { columns } from "../../standardEntities/user";
import { defaultSearchFields } from "../../standardEntities/user";
import { User } from "./UserTypes";
import { isAdminUser } from "../../services/utils";

const getDefaultRecordSearchRequest = () => {
  return { name: "", userId: "", email: "" };
};

function UserViewForm({ currentUser }) {
  const location = useLocation();
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [recordSearchRequest, setRecordSeachRequest] = useState(getDefaultRecordSearchRequest());

  const isMyProfilePage: boolean = location.pathname === "/my-profile/view";

  useEffect(() => {
    if (isMyProfilePage) {
      fetchAndUpdateCurrentUser();
    } else {
      setSelectedRecord(null);
    }

    async function fetchAndUpdateCurrentUser() {
      return apiCall("get", `/api/entity/user/${currentUser.id}`)
        .then((response) => {
          if (response == null) {
            console.log("Null response for records fetch");
            return;
          }
          return setSelectedRecord(response);
        })
        .catch((err) => {
          console.log("Error fetching records", err);
          return null;
        });
    }
  }, [location.pathname, currentUser, isMyProfilePage]);

  const setSelectedRecordIfAllowed = (record: User) => {
    //we set the record only if our current user is allowed to view and edit a user
    if (isAdminUser(currentUser) || currentUser.superUser) {
      setSelectedRecord(record);
    }
  };

  return (
    <>
      {selectedRecord && (
        <RecordFullViewForm
          recordId={selectedRecord.id}
          selectedRecord={selectedRecord}
          setSelectedRecord={setSelectedRecord}
          creationAllowed={false}
          entityType="user"
          pageTitle={isMyProfilePage ? "My Profile" : "User"}
          isEditable={() => !isMyProfilePage || isAdminUser(currentUser) || currentUser.superUser}
          hasBackButton={() => !isMyProfilePage}
        />
      )}
      {!selectedRecord && (
        <RecordViewForm
          columns={columns}
          pageTitle="Users"
          creationAllowed={false}
          recordSearchRequest={recordSearchRequest}
          setRecordSeachRequest={setRecordSeachRequest}
          setSelectedRecord={setSelectedRecordIfAllowed}
          searchFields={defaultSearchFields}
          getDefaultRecordSearchRequest={getDefaultRecordSearchRequest}
          entityType="user"
        />
      )}
    </>
  );
}

export default withAuth(UserViewForm);
