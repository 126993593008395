import withAuth from "../../hocs/withAuth";
import GenerateReportForm from "./GenerateReportForm";
import { stockViewExcelColumns, stockViewInwardExcelColumns, stockViewRowExcelColumns } from "../../standardEntities/stockView";
import { translatePicklistValue } from "../../services/fieldsUtils";
import { stockReportCategoryValues, stockReportFormFields } from "./formFields/stockReportingFormFields";

const getExcelColumns = (searchRequest) => {
  return reportCategoryToExcelColumns[searchRequest.reportCategory];
};

const reportCategoryToExcelColumns = {
  ITEM_SUMMARY: stockViewExcelColumns,
  INWARD_SUMMARY: stockViewInwardExcelColumns,
  FULL_DETAIL: stockViewRowExcelColumns,
};

const getReportName = (searchRequest) => {
  return translatePicklistValue(searchRequest.reportCategory, stockReportCategoryValues);
};

const StockReportForm = ({ formMinWidth = "38vw" }) => {
  return (
    <GenerateReportForm
      formMinWidth={formMinWidth}
      fields={stockReportFormFields}
      getExcelColumns={getExcelColumns}
      pageTitle={"Stock Reports"}
      getReportName={getReportName}
      reportType={"stock"}
    />
  );
};

export default withAuth(StockReportForm);
