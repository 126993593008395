//todo: move all this to specific handlers
import { nullSafeString } from "./stringUtils";
import { formatDate } from "./dateUtils";
import {
  materialTypeValues,
  inTransactionTypeValues,
  receiverDocTypeValues,
  transportModeValues,
  senderDocTypeValues,
} from "../standardEntities/materialInwardFields";

import { locatorTypeValues } from "../standardEntities/locator";
import { projectTypeValues } from "../standardEntities/project";
import { workTypeValues } from "../standardEntities/mbSheetFields";
import { translatePicklistValue } from "./fieldsUtils";
import { outTransactionTypeValues } from "../standardEntities/materialOutward";
import { Field } from "../types/field";

export const generatePDFContent = (fields: Field[], record, recordType) => {
  let title = [[""]];
  let senderReceiverData = [[""]];
  let dtHead = [];
  let details = [];
  let invoiceValues = [[""]];
  let remarks = [[""]];
  let otherValues = [[""]];
  if (recordType === "material-inward") {
    title = [["MATERIAL INWARD RECEIPT"]];
    console.log("MATERIAL INWARD RECEIPT::", record);
    senderReceiverData = [
      [
        `MIR No: ${record.id} \nMIR Date: ${formatDate(record.docDate)} \nMaterial Type: ${translatePicklistValue(
          record.materialType,
          materialTypeValues
        )}\nTrans Type: ${translatePicklistValue(record.transactionType, inTransactionTypeValues)}\nRecv Doc: ${translatePicklistValue(
          record.receiverDocType,
          receiverDocTypeValues
        )} \nDoc No: ${nullSafeString(record.receiverDocNo)} \nDoc Date: ${formatDate(record.receiverDocDate)} `,
        `Receiver: ${record.docCompanyName} \n${record.docAddress1} \n${nullSafeString(record.docAddress2)} \nContractor: ${
          record.docContractorName
        } (${record.docContractorCode}) \nStation: ${record.docStationName} (${record.docStateName}) \t\tGST No: ${nullSafeString(
          record.docGSTNo
        )} \nLocator: ${record.docLocatorName} (${record.docLocatorCode}) \t\tLocator Type: ${translatePicklistValue(
          record.docLocatorType,
          locatorTypeValues
        )} \nProject: ${record.projectName} (${record.projectCode}) \t\tVirtual Locator: ${record.virtualLocator}`,
      ],
      [
        `Total Packages: ${record.totalPkgs} \nInvoice Value: ${record.totalValue} \nInventory Value: ${
          record.inventoryValue
        }  \nSender Doc: ${translatePicklistValue(record.senderDocType, senderDocTypeValues)} \nDoc No: ${nullSafeString(
          record.senderDocNo
        )} \nDoc Date: ${formatDate(record.senderDocDate)} \nSender Material: ${translatePicklistValue(
          nullSafeString(record.senderMaterialType ?? record.materialType),
          materialTypeValues
        )}`,
        `Sender: ${record.senderCompanyName} \n${record.senderAddress1} \n${nullSafeString(record.senderAddress2)} \nContractor: ${nullSafeString(
          record.senderContractorName
        )} (${nullSafeString(record.senderContractorCode)}) \nStation: ${record.senderStationName} (${
          record.senderStateName
        }) \t\tGST No: ${nullSafeString(record.senderGSTNo)} \nLocator: ${record.senderLocatorName} (${
          record.senderLocatorCode
        }) \t\tLocator Type: ${translatePicklistValue(record.senderLocatorType, locatorTypeValues)} \nProject: ${nullSafeString(
          record.senderProjectName
        )} (${nullSafeString(record.senderProjectCode)}) \t\tVirtual Locator: ${nullSafeString(record.senderVirtualLocator)} `,
      ],
    ];
    dtHead = [["SN", "Description Of Material", "Part Code", "Item Id", "UOM", "Recvd Qty", "Basic Value"]];
    details = record.details.map((row, index) => {
      return [index + 1, row.itemName, row.itemCustCode, row.itemId, row.uom, row.totalQuantity, row.basicAmount];
    });
    let totalInwardQuantity = record.details.reduce((total, detail) => {
      return total + parseFloat(detail.totalQuantity);
    }, 0);
    details.push(["", "SubTotal", "", "", "", "", totalInwardQuantity.toFixed(1), "", record.basicValue.toFixed(2)]);
    invoiceValues = [
      [
        `IGST Value:\nCGST Value:\nSGST Value:\nFreight Value:`,
        `${record.iGSTValue.toFixed(2)}\n${record.cGSTValue.toFixed(2)}\n${record.sGSTValue.toFixed(2)}\n${record.freightValue.toFixed(2)}`,
      ],
      [`Total Value:`, `${record.totalValue.toFixed(2)}`],
    ];
    remarks = [[`Remarks: ${nullSafeString(record.remarks)}`]];
    otherValues = [
      [
        `Gate Entry No: ${nullSafeString(record.gateEntryNo)} \nGate Entry Date: ${formatDate(record.gateEntryDate)} \nVehicle No: ${nullSafeString(
          record.vehicleNo
        )}\t\t\t\t\t\t\t\t\t\t`,
        `Transport Mode: ${translatePicklistValue(record.transportMode, transportModeValues)} \nTransport Vendor: ${
          record.vendorName
        } (${nullSafeString(record.vendorCode)}) \nConsignment Note: ${nullSafeString(record.consignmentNote)}\t\t\t\t\t\t\t\t\t\t`,
        `Status: ${record.submit ? "Submitted" : record.cancelled ? "Cancelled" : "Submit Pending"} \nEway Bill: ${nullSafeString(
          record.ewayBillNo
        )}\t\t\t\t\t\t\t\t\t\t`,
      ],
    ];

    return {
      rows: [
        { headers: title, halign: "center" },
        { body: senderReceiverData },
        { headers: dtHead, body: details, halign: "right" },
        { body: invoiceValues, halign: "right" },
        { body: remarks, halign: "left" },
        { body: otherValues },
      ],
      footer: "ONLY FOR INTERNAL USE",
    };
  } else if (recordType === "material-outward") {
    title = [["MATERIAL OUTWARD AUTHORIZATION"]];
    senderReceiverData = [
      [
        `MOA No: ${record.id} \nMOA Date: ${formatDate(record.docDate)} \nMaterial Type: ${translatePicklistValue(
          record.materialType,
          materialTypeValues
        )}\nTrans Type: ${translatePicklistValue(record.transactionType, outTransactionTypeValues)}\nSender Doc: ${translatePicklistValue(
          record.senderDocType,
          senderDocTypeValues
        )}\nDoc No: ${nullSafeString(record.senderDocNo)} \nDoc Date: ${formatDate(record.senderDocDate)} `,
        `Sender: ${record.docCompanyName} \n${record.docAddress1} \n${nullSafeString(record.docAddress2)} \nContractor: ${
          record.docContractorName
        } (${record.docContractorCode}) \nStation: ${record.docStationName} (${record.docStateName}) \t\tGST No: ${nullSafeString(
          record.docGSTNo
        )} \nLocator: ${record.docLocatorName} (${record.docLocatorCode}) \t\tLocator Type: ${translatePicklistValue(
          record.docLocatorType,
          locatorTypeValues
        )} \nProject: ${record.projectName} (${record.projectCode}) \t\tVirtual Locator: ${record.virtualLocator}`,
      ],
      [
        `Total Value: ${record.totalValue.toFixed(2)} \n\n\nRecv Doc: ${translatePicklistValue(
          record.receiverDocType,
          receiverDocTypeValues
        )} \nDoc No: ${nullSafeString(record.receiverDocNo)} \nDoc Date: ${formatDate(
          record.receiverDocDate
        )} \nReceiver Material: ${translatePicklistValue(nullSafeString(record.receiverMaterialType ?? record.materialType), materialTypeValues)}`,
        `Receiver: ${record.receiverCompanyName} \n${record.receiverAddress1} \n${nullSafeString(
          record.receiverAddress2
        )} \nContractor: ${nullSafeString(record.receiverContractorName)} (${nullSafeString(record.receiverContractorCode)}) \nStation: ${
          record.receiverStationName
        } (${record.receiverStateName}) \t\tGST No: ${nullSafeString(record.receiverGSTNo)} \nLocator: ${record.receiverLocatorName} (${
          record.receiverLocatorCode
        }) \t\tLocator Type: ${translatePicklistValue(record.receiverLocatorType, locatorTypeValues)} \nProject: ${nullSafeString(
          record.receiverProjectName
        )} (${nullSafeString(record.receiverProjectCode)}) \t\tVirtual Locator: ${nullSafeString(record.receiverVirtualLocator)} `,
      ],
    ];

    dtHead = [["SN", "Description Of Material", "Part Code", "Item Id", "UOM", "MIR No", "Row No", "Out Qty", "Value"]];

    details = record.details.map((row, index) => {
      return [
        index + 1,
        row.itemName,
        row.itemCustCode,
        row.itemId,
        row.uom,
        row.mirId,
        row.mirRowId,
        row.totalQuantity.toFixed(1),
        row.totalAmount.toFixed(2),
      ];
    });

    let totalDetailQuantity = record.details.reduce((total, detail) => {
      return total + parseFloat(detail.totalQuantity);
    }, 0);

    details.push(["", "Totals", "", "", "", "", "", "", "", totalDetailQuantity.toFixed(1), record.totalValue.toFixed(2)]);

    remarks = [[`Remarks: ${nullSafeString(record.remarks)}`]];
    otherValues = [
      [
        `Request By: ${nullSafeString(record.requestBy)} \nRequest Ref No: ${nullSafeString(
          record.requestRefNo
        )} \t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t`,
        `Status: ${record.submit ? "Submitted" : record.cancelled ? "Cancelled" : "In Process"}\nGate Pass Made: ${
          record.gpFlg ? "Yes" : "No"
        }\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t`,
        `Gate Pass No: ${nullSafeString(record.gpId)} \nGate Pass Date: ${formatDate(record.gpDate)}\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t`,
      ],
    ];

    return {
      rows: [
        { headers: title, halign: "center" },
        { body: senderReceiverData },
        { headers: dtHead, body: details, halign: "right" },
        { body: remarks, halign: "left" },
        { body: otherValues },
      ],
      footer: "ONLY FOR INTERNAL USE",
    };
  } else if (recordType === "mb-sheet") {
    title = [["MB SHEET"]];
    senderReceiverData = [
      [
        `MBS No: ${record.id} \nMBS Date: ${formatDate(record.docDate)} \nMaterial Type: ${translatePicklistValue(
          record.materialType,
          materialTypeValues
        )}\n\nProject Type: ${translatePicklistValue(record.projectType, projectTypeValues)}`,
        `Contractor: ${record.contractorName} (${record.contractorCode}) \nLocator: ${record.docLocatorName} (${record.docLocatorCode})\nSender: ${
          record.docCompanyName
        } (${record.docCompanyCode}) \t\tGST No: ${nullSafeString(record.docGSTNo)}  \nStation: ${record.docStationName} \t\tState: ${
          record.docStateName
        }\nProject: ${record.projectName} (${record.projectCode}) \t\tVirtual Locator: ${record.virtualLocator}`,
      ],
      [
        `${record.jobId === null ? `CWO Id:` : `Job Id:`} ${record.jobId === null ? record.cwoId : record.jobId} \n\n${
          record.jobId === null ? `CWO Date:` : `PO No:`
        } ${record.jobId === null ? formatDate(record.cwoDate) : record.poNo}`,
        `${record.jobId === null ? `CWO No:` : `Site Id:`} ${record.jobId === null ? record.cwoNo : record.siteId}\n${
          record.jobId === null ? `CWO Type:` : `Job Type:`
        } ${translatePicklistValue(record.jobId === null ? record.cwoType : record.jobType, workTypeValues)}  \t\t${
          record.jobId === null ? `Work Area:` : ``
        } ${record.jobId === null ? record.workArea : ``}\nRoute Length: ${record.routeLength} \t\t\t\t${record.jobId === null ? `Home Pass:` : ``} ${
          record.jobId === null ? record.homePass : ``
        }`,
      ],
    ];

    dtHead = [["SN", "Description Of Material", "Part Code", "Item Id", "UOM", "Out Qty", "Value"]];

    details = record.details.map((row, index) => {
      return [index + 1, row.itemName, row.itemCustCode, row.itemId, row.uom, row.totalQuantity.toFixed(1), row.totalAmount.toFixed(2)];
    });

    let totalDetailQuantity = record.details.reduce((total, detail) => {
      return total + parseFloat(detail.totalQuantity);
    }, 0);

    let totalDetailAmount = record.details.reduce((total, detail) => {
      return total + parseFloat(detail.totalAmount);
    }, 0);

    details.push(["", "Totals", "", "", "", totalDetailQuantity.toFixed(1), totalDetailAmount.toFixed(2)]);

    remarks = [[`Remarks: ${nullSafeString(record.remarks)}`]];

    otherValues = [
      [
        `Closure No: ${nullSafeString(record.closureId)} \t\t\t\t\t\t\t\t\t\t\t\t\t\t\t`,
        `Closure Date: ${record.closureId === null ? `` : formatDate(record.closureDate)}\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t`,
        `Status: ${record.submit ? "Submitted" : record.cancelled ? "Cancelled" : "Not Submitted"}\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t`,
      ],
    ];

    return {
      rows: [
        { headers: title, halign: "center" },
        { body: senderReceiverData },
        { headers: dtHead, body: details, halign: "right" },
        { body: remarks, halign: "left" },
        { body: otherValues },
      ],
      footer: "ONLY FOR INTERNAL USE",
    };
  } else if (recordType === "gate-pass") {
    title = [["GATE PASS"]];
    senderReceiverData = [
      [
        `Gate Pass No: ${record.id} \nGate Pass Date: ${formatDate(record.docDate)} \nMaterial Type: ${translatePicklistValue(
          record.materialType,
          materialTypeValues
        )}\nTrans Type: ${translatePicklistValue(record.transactionType, outTransactionTypeValues)}\nSender Doc: ${translatePicklistValue(
          record.senderDocType,
          senderDocTypeValues
        )}\nDoc No: ${nullSafeString(record.senderDocNo)} \nDoc Date: ${formatDate(record.senderDocDate)} `,
        `Sender: ${record.docCompanyName} \n${record.docAddress1} \n${nullSafeString(record.docAddress2)} \nContractor: ${nullSafeString(
          record.docContractorName
        )} (${nullSafeString(record.docContractorCode)}) \nStation: ${record.docStationName} (${record.docStateName}) \t\tGST No: ${nullSafeString(
          record.docGSTNo
        )} \nLocator: ${record.docLocatorName} (${record.docLocatorCode}) \t\tLocator Type: ${translatePicklistValue(
          record.docLocatorType,
          locatorTypeValues
        )} \nProject: ${record.projectName} (${record.projectCode}) \t\tVirtual Locator: ${nullSafeString(record.virtualLocator)}`,
      ],
      [
        `MOA No: ${record.moaId} \nMOA Date: ${formatDate(record.moaDate)}, \n\nRecv Doc: ${translatePicklistValue(
          record.receiverDocType,
          receiverDocTypeValues
        )} \nDoc No: ${nullSafeString(record.receiverDocNo)}\nDoc Date: ${formatDate(
          record.receiverDocDate
        )} \nReceiver Material: ${translatePicklistValue(nullSafeString(record.receiverMaterialType ?? record.materialType), materialTypeValues)}`,
        `Receiver: ${record.receiverCompanyName} \n${record.receiverAddress1} \n${nullSafeString(
          record.receiverAddress2
        )} \nContractor: ${nullSafeString(record.receiverContractorName)} (${nullSafeString(record.receiverContractorCode)}) \nStation: ${
          record.receiverStationName
        } (${record.receiverStateName}) \t\tGST No: ${nullSafeString(record.receiverGSTNo)} \nLocator: ${record.receiverLocatorName} (${
          record.receiverLocatorCode
        }) \t\tLocator Type: ${translatePicklistValue(record.receiverLocatorType, locatorTypeValues)} \nProject: ${nullSafeString(
          record.receiverProjectName
        )} (${nullSafeString(record.receiverProjectCode)}) \t\tVirtual Locator: ${nullSafeString(record.receiverVirtualLocator)}  `,
      ],
    ];

    dtHead = [["SN", "Description Of Material", "Part Code", "Item Id", "UOM", "MIR No", "Row No", "Part Serial No", "Batch No", "Out Qty", "Value"]];

    details = record.details.map((row, index) => {
      return [
        index + 1,
        row.itemName,
        row.itemCustCode,
        row.itemId,
        row.uom,
        row.mirId,
        row.mirRowId,
        row.partSerialNo,
        row.batchNo,
        row.totalQuantity.toFixed(1),
        row.totalAmount.toFixed(2),
      ];
    });

    let totalDetailQuantity = record.details.reduce((total, detail) => {
      return total + parseFloat(detail.totalQuantity);
    }, 0);

    details.push(["", "Totals", "", "", "", "", "", "", "", totalDetailQuantity.toFixed(1), record.totalValue.toFixed(2)]);

    remarks = [[`Remarks: ${nullSafeString(record.remarks)}`]];

    otherValues = [
      [
        `Total Value: ${record.totalValue.toFixed(2)} \nTotal Packages: ${record.totalPkgs} \nVehicle No: ${nullSafeString(
          record.vehicleNo
        )}\t\t\t\t\t\t\t\t\t\t`,
        `Transport Mode: ${translatePicklistValue(record.transportMode, transportModeValues)} \nTransport Vendor: ${
          record.vendorName
        } (${nullSafeString(record.vendorCode)}) \nConsignment Note: ${nullSafeString(record.consignmentNote)}\t\t\t\t\t\t\t\t\t\t`,
        `Expected Delivery: ${formatDate(record.expectedDate)}\nEway Bill: ${nullSafeString(record.ewayBillNo)}\nStatus: ${
          record.cancelled ? "Cancelled" : record.delivered ? "Delivered" : "Delivery Pending"
        }\t\t\t\t\t\t\t\t\t\t`,
      ],
      [
        `Delivery Date: ${formatDate(record.deliveryDate)}\t\t\t\t\t\t\t\t\t\t`,
        `Receiver Person: ${nullSafeString(record.receiverPerson)}\t\t\t\t\t\t\t\t\t\t`,
        `Receiver Mobile: ${nullSafeString(record.receiverMobile)}\t\t\t\t\t\t\t\t\t\t`,
      ],
    ];

    return {
      rows: [
        { headers: title, halign: "center" },
        { body: senderReceiverData },
        { headers: dtHead, body: details, halign: "right" },
        { body: remarks },
        { body: otherValues },
      ],
      footer: "ONLY FOR INTERNAL USE",
    };
  }

  return {};
};

const convertContentToLength = (content: string, length: number, truncate: boolean = false) => {
  if (content.length > length) {
    return truncate ? content.substring(0, length) : content;
  } else if (content.length < length) {
    return content + " ".repeat(length - content.length);
  }
  return content;
};
