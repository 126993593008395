import React from "react";
import { Provider } from "react-redux";
import store from "../store";
import { BrowserRouter as Router } from "react-router-dom";
// import Navbar from "./Navbar";
import Main from "./Main";
import { setAuthorizationToken, setCurrentUser } from "../store/actions/auth";
import jwtDecode from "jwt-decode";
import { CurrentUserContext } from "./CurrentUserContext.js";
// import Layout from "./Layout.jsx";
import { Box } from "@mui/material";
import SideDrawer from "./SideDrawer.jsx";
// import dayjs from 'dayjs';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { SuccessMessageProvider } from "../components/reports/SuccessMessageProvider.jsx";

let currentUser = {};

const theme = createTheme({
  typography: {
    fontSize: 13 // Default font size is 14px
  },
});

if (localStorage.jwtToken) {
  setAuthorizationToken(localStorage.jwtToken);
  // prevent someone from manually tampering with the key of jwtToken in localStorage
  try {
    currentUser = jwtDecode(localStorage.jwtToken);
    store.dispatch(setCurrentUser(currentUser));
  } catch (e) {
    localStorage.removeItem("jwtToken");
    store.dispatch(setCurrentUser({}));
  }
}

const App = () => (
  <ThemeProvider theme={theme}>
  <CurrentUserContext.Provider value={currentUser}>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Provider store={store}>
        <SuccessMessageProvider>
          <Router>
            <div className="onboarding">
              <Box sx={{ display: "flex" }}>
                <SideDrawer />
                <Main />
              </Box>
            </div>
          </Router>
        </SuccessMessageProvider>
      </Provider>
    </LocalizationProvider>
  </CurrentUserContext.Provider>
  </ThemeProvider>
);

export default App;
