export const isValidateUser = ({ user, setFormErrors }) => {
  const newErrors = {};

  // Check if fields are non-empty
  // Object.entries(user).forEach(([field, value]) => {
  //   if (isEmptyObj(value)) {
  //     newErrors[field] = `${field} is required`;
  //   }
  // });

  // Check if the email is a valid email address
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  if (!emailRegex.test(user.email)) {
    newErrors.email = "Invalid email address";
  }
  const phoneRegex = /^\d{10}$/;
  const phoneString = user.mobile.toString().trim();

  if (phoneString.length !== 10 || !phoneRegex.test(phoneString)) {
    newErrors.mobile = "Mobile number must have exactly 10 digits";
  }

  if (phoneString[0] === "0") {
    newErrors.mobile = "Mobile number must not have leading 0";
  }

  setFormErrors(newErrors);
  // Return true if there are no errors, otherwise false
  return Object.keys(newErrors).length === 0;
};
