import withAuth from "../../hocs/withAuth";
import GenerateReportForm from "./GenerateReportForm";
import { stockOpenCloseAvailableColumns, stockOpenClosePhysicalColumns } from "../../standardEntities/stockView";
import { stockTypeValues } from "./reportUtils";
import { translatePicklistValue } from "../../services/fieldsUtils";
import { stockOpeningClosingFormFields } from "./formFields/stockOpeningClosingFormFields";

const getExcelColumns = (searchRequest) => {
  return reportCategoryToExcelColumns[searchRequest.stockType];
};

const reportCategoryToExcelColumns = {
  A: stockOpenCloseAvailableColumns,
  P: stockOpenClosePhysicalColumns,
};

const StockReportForm = ({ formMinWidth = "38vw" }) => {
  return (
    <GenerateReportForm
      formMinWidth={formMinWidth}
      fields={stockOpeningClosingFormFields}
      getExcelColumns={getExcelColumns}
      pageTitle={"Stock Opening Closing Report"}
      getReportName={(searchRequest) => `Stock Opening Closing Report (${translatePicklistValue(searchRequest.stockType, stockTypeValues)})`}
      reportType={"stock-opening-closing"}
    />
  );
};

export default withAuth(StockReportForm);
