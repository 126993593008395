import dayjs from "dayjs";
import moment from "moment-timezone";
import { openingStockDate, timeZone } from "../constants/globalConstants";
import { evaluateValueScript, getConstraintByType } from "./utils";
import { DateRangeConstraint } from "../types/constraint";
export const dateFormat = "DD/MM/YYYY";

export const getYesterday = () => {
  let yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  return dayjs(yesterday);
};

export const getOneMonthAgo = () => {
  return dayjs().subtract(1, "month");
};

export const formatDate = (value) => {
  if (value === null || value === undefined) {
    return "";
  }
  return moment(value).tz(timeZone).format(dateFormat);
};

export const getMinMaxDateForField = (field, record, globalConstants) => {
  let dateRangeConstraints = getConstraintByType(field.constraints, "DATE_RANGE");
  if (!dateRangeConstraints) {
    return { minDate: null, maxDate: null };
  }
  return getMinMaxDateConstraintWithGlobal(dateRangeConstraints, record, globalConstants);
};

export const getMinMaxDateConstraint = (constraint: DateRangeConstraint, record) => {
  return getMinMaxDateConstraintWithGlobal(constraint, record, {
    openingStockDate,
    today: dayjs().startOf("day"),
  });
};

export const getMinMaxDateConstraintWithGlobal = (
  { minDate, minDateScript, maxDate, maxDateScript, beforeNow, afterNow }: DateRangeConstraint,
  record: any,
  globalConstants: any
) => {
  minDate = minDate ? dayjs(minDate) : null;
  let newGlobalConstants = {
    ...globalConstants,
    openingStockDate,
    today: dayjs().startOf("day"),
  };
  if (minDateScript) {
    let scriptValue = evaluateValueScript(minDateScript, record, newGlobalConstants);
    minDate = scriptValue ? dayjs(scriptValue) : null;
  }
  maxDate = maxDate ? dayjs(maxDate) : null;
  if (maxDateScript) {
    let scriptValue = evaluateValueScript(maxDateScript, record, newGlobalConstants);
    maxDate = scriptValue ? dayjs(scriptValue) : null;
  }

  // Handle minDate
  getMinDate();
  // Handle maxDate
  getMaxDate();
  return { minDate, maxDate };

  function getMaxDate() {
    const today = dayjs().endOf("day");
    if (maxDate) {
      if (beforeNow) {
        maxDate = minDateComp(maxDate, today); // Min of calculated maxDate and today
      }
      // else, maxDate remains the same
    } else {
      if (beforeNow) {
        maxDate = today; // Set maxDate to today
      } else {
        maxDate = null;
      }
    }
  }

  function getMinDate() {
    const today = dayjs().startOf("day");
    if (minDate) {
      if (afterNow) {
        minDate = maxDateComp(minDate, today); // Max of calculated minDate and today
      }
      // else, minDate remains the same
    } else {
      if (afterNow) {
        minDate = today; // Set minDate to today
      } else {
        minDate = null;
      }
    }
  }
};

function minDateComp(date1, date2) {
  if (!date1) return date2;
  if (!date2) return date1;
  return dayjs(date1).isBefore(dayjs(date2)) ? date1 : date2;
}

function maxDateComp(date1, date2) {
  if (!date1) return date2;
  if (!date2) return date1;
  return dayjs(date1).isAfter(dayjs(date2)) ? date1 : date2;
}
